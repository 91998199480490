import skattepliktReducer, * as fromSkatteplikt from './skatteplikt';
import feilReducer, * as fromFeil from './feil';
import beregnetSkattReducer, * as fromBeregnetSkatt from './beregnetSkatt';
import dialogReducer, * as fromDialog from './dialog';
import visningsdataReducer, * as fromVisningsdata from './visningsdata';
import visningsdataEktefelleReducer from './visningsdataEktefelle';
import spinnerReducer from './spinner';
import visningsdefinisjonReducer, * as fromVisningsdefinisjon from './visningsdefinisjon';
import navigasjonsReducer, * as fromNavigasjon from './navigasjon';
import soekReducer, * as fromSoek from './soek';
import fokusReducer from '../fokus/fokus.reducer';
import intlReducer, * as fromIntl from './intl';
import systemmeldingDialogReducer, * as fromSystemmeldingDialog from './systemmeldingDialog';
import { finnDefinisjonGittKorttypereferanse } from '@skatteetaten/visningsdefinisjoner-og-tekster/lib/visningslogikk/utils';
import { EKTEFELLESIDE } from './navigasjon';

export default function rootReducer(state = {}, action = {}) {
  const gammelDialog = state.dialog;

  const navigasjon = navigasjonsReducer(state.navigasjon, action);
  const skatteplikt = skattepliktReducer(state.skatteplikt, action);
  const feil = feilReducer(state.feil, action);
  const beregnetSkatt = beregnetSkattReducer(
    state.beregnetSkatt,
    action,
    false,
  );
  const beregnetSkattEktefelle = beregnetSkattReducer(
    state.beregnetSkattEktefelle,
    action,
    true,
  );
  const spinner = spinnerReducer(state.spinner, action);
  const visningsdefinisjon = visningsdefinisjonReducer(
    state.visningsdefinisjon,
    action,
  );
  const visningsdataSkattemelding = visningsdataReducer(
    state.visningsdata,
    action,
    fromVisningsdefinisjon.getVisningsdefinisjon(visningsdefinisjon),
    gammelDialog,
  );
  const visningsdataEktefelle = visningsdataEktefelleReducer(
    state.visningsdataEktefelle,
    action,
    fromVisningsdefinisjon.getVisningsdefinisjon(visningsdefinisjon),
    gammelDialog,
  );
  const aktivVisningsdata =
    fromNavigasjon.getGjeldendeSide(navigasjon) === EKTEFELLESIDE
      ? visningsdataEktefelle
      : visningsdataSkattemelding;

  const dialog = dialogReducer(state.dialog, action, {
    inntektsaar: getInntektsaar(state),
    skatteplikt: getSkattepliktVisningsdata(state),
    visningsdata: aktivVisningsdata,
  });

  const soek = soekReducer(state.soek, action);

  const fokus = fokusReducer(state.fokus, action, gammelDialog);

  const intl = intlReducer(state.intl, action);

  const systemmeldingDialog = systemmeldingDialogReducer(
    state.systemmeldingDialog,
    action,
  );

  return {
    navigasjon,
    skatteplikt,
    feil,
    dialog,
    visningsdata: visningsdataSkattemelding,
    visningsdataEktefelle,
    beregnetSkatt,
    beregnetSkattEktefelle,
    spinner,
    visningsdefinisjon,
    soek,
    fokus,
    intl,
    systemmeldingDialog,
  };
}

export const getGjeldendeSide = (state) =>
  fromNavigasjon.getGjeldendeSide(state.navigasjon);

export const getSynligSeksjon = (state) =>
  fromNavigasjon.getSynligSeksjon(state.navigasjon);

export const getAktivBeregnetSkatt = (state) =>
  fromNavigasjon.getGjeldendeSide(state.navigasjon) === EKTEFELLESIDE
    ? state.beregnetSkattEktefelle
    : state.beregnetSkatt;

export const getBeregnetSkatt = (state) =>
  fromBeregnetSkatt.getBeregnetSkatt(getAktivBeregnetSkatt(state));
export const getBeregnetSkattSvalbard = (state) =>
  fromBeregnetSkatt.getBeregnetSkattSvalbard(getAktivBeregnetSkatt(state));

export const getSummertSkattegrunnlagForVisning = (state) =>
  fromBeregnetSkatt.getSummertSkattegrunnlagForVisning(
    getAktivBeregnetSkatt(state),
  );
export const getSummertSkattegrunnlagForVisningSvalbard = (state) =>
  fromBeregnetSkatt.getSummertSkattegrunnlagForVisningSvalbard(
    getAktivBeregnetSkatt(state),
  );
export const getSkattemeldingResultatTilgjengelig = (state) =>
  fromBeregnetSkatt.getSkattemeldingResultatTilgjengelig(
    getAktivBeregnetSkatt(state),
  );
export const getBeregningPaagaar = (state) =>
  fromBeregnetSkatt.getBeregningPaagaar(getAktivBeregnetSkatt(state));

export const getFeilIDialog = (state) => fromFeil.getFeilIDialog(state.feil);
export const getDialog = (state) => state.dialog;
export const getDialogIsOpen = (state) => fromDialog.getIsOpen(state.dialog);
export const getDialogtype = (state) => fromDialog.getDialogtype(state.dialog);
export const getKortIKortDialogProps = (state) =>
  fromDialog.getKortIKortDialogProps(state.dialog);
export const getSistOkKlikketTimestamp = (state) =>
  fromDialog.getSistOkKlikketTimestamp(state.dialog);
export const getKortdefinisjonIDialog = (state) =>
  fromDialog.getKortdefinisjon(state.dialog);
export const getKortgruppeIDialog = (state) =>
  fromDialog.getKortgruppeIDialog(state.dialog);
export const getUnderforekomstIDialog = (dataSti) => (state) =>
  fromDialog.getUnderforekomstIDialog(dataSti, state.dialog);
export const getDialogTemaId = (state) =>
  fromDialog.getDialogTemaId(state.dialog);
export const getDialogFeltSomErEndret = (state) =>
  fromDialog.getDialogFeltSomErEndret(state.dialog);
export const getTypeIDialog = (state) =>
  fromDialog.getTypeIDialog(state.dialog);
export const getErNyDialog = (state) => fromDialog.getErNyDialog(state.dialog);
export const getDataIDialog = (state) => fromDialog.getData(state.dialog);
export const getDataIDialogVasket = (state) =>
  fromDialog.getDataIDialogVasket(state.dialog);
export const getNyBehandlingsart = (state) =>
  fromDialog.getNyBehandlingsart(state.dialog);
export const getSynligeEgenskaperIDialog = (state) =>
  fromDialog.getSynligeEgenskaper(state.dialog);
export const getLeggTilEgenskaperDialog = (state) =>
  fromDialog.getNyeEgenskaper(state.dialog);
export const getForekomstForDialog = (state) =>
  fromDialog.getForekomstForDialog(state.dialog);
export const getDialogErITabellredigering = (state) =>
  fromDialog.getDialogErITabellredigering(state.dialog);
export const getKodelisteConfig = (state) =>
  fromDialog.getKodelisteConfig(state.dialog);

export const getAktivVisningsdata = (state) =>
  fromNavigasjon.getGjeldendeSide(state.navigasjon) === EKTEFELLESIDE
    ? getVisningsdataEktefelle(state)
    : getVisningsdataSkattemelding(state);
export const getVisningsdata = (state) => getAktivVisningsdata(state);
export const getVisningsdataSkattemelding = (state) => state.visningsdata;

export const getVisningsdataEktefelle = (state) => state.visningsdataEktefelle;

export const getForekomster = (referanse) => (state) =>
  fromVisningsdata.getForekomster(getVisningsdata(state), referanse);

export const getSkatteplikt = (state) => state?.skatteplikt;
// TODO ha disse inni her eller i reduceren?
export const getSkattepliktVisningsdata = (state) =>
  getSkatteplikt(state)?.skattepliktVisningsdata;
export const getSkattepliktsopplysningerSide = (state) =>
  fromSkatteplikt.getSkattepliktsopplysningerSide(state.skatteplikt);
export const getInntektsaar = (state) =>
  fromSkatteplikt.getSkatteberegningsaar(state?.skatteplikt);

export const getUnderforekomsttypeIDialog = (dataSti) => (state) =>
  fromDialog.getUnderforekomsttypeIDialog(dataSti)(state.dialog);

export const getVisningsdefinisjon = (state) =>
  fromVisningsdefinisjon.getVisningsdefinisjon(state.visningsdefinisjon);
export const getDefinisjonsstiForId = (id) => (state) =>
  fromVisningsdefinisjon.getDefinisjonsstiForId(id, state.visningsdefinisjon);
export const getDefinisjonForId = (id) => (state) =>
  fromVisningsdefinisjon.getDefinisjonForId(id, state.visningsdefinisjon);

export const getTemaFraKorttype = (korttype) => (state) =>
  finnDefinisjonGittKorttypereferanse(
    korttype?.referanse,
    'tema',
    getVisningsdefinisjon(state),
  );

export const getErPaaNaeringsspesifikasjonSide = (_state) => false;
export const getLocale = (state) => fromIntl.getLocale(state.intl);

export const getSoekemotor = (state) => fromSoek.getSoekemotor(state.soek);
export const getSoekeStreng = (state) => fromSoek.getSoekeStreng(state.soek);
export const getSoekeResultat = (state) => fromSoek.getResultat(state.soek);

export const getSystemmeldingDialogIsOpen = (state) =>
  fromSystemmeldingDialog.getSystemmeldingDialogIsOpen(
    state.systemmeldingDialog,
  );
export const getSystemfeil = (state) =>
  fromSystemmeldingDialog.getSystemfeil(state.systemmeldingDialog);

import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { CalculatorSVGpath } from '@skatteetaten/ds-icons';

import style from './Forside.module.scss';
import { Icon, Panel, Tabs } from '../components/DS';
import { EstimertSkattTopp } from '../components/EstimertSkattTopp';
import { FormattedHTMLMessageWithFallbackToUgly } from '../components/FormattedHTMLMessage';
import { useKildeSkattPaaLoenn } from '../components/kildeskattePaaLoenn/useKildeskattPaaLoenn';
import { Skattemeldingside } from '../components/SkattemeldingSide';
import { EKTEFELLESIDE, SKATTEMELDINGSIDE } from '../../reducers/navigasjon';
import { getErSivilstandGift } from '../../reducers/skatteplikt';
import { ScrollToTopButton } from '@skatteetaten/ds-buttons';
import { getGjeldendeSide } from '../../reducers';
import {
  gaaTilEktefelleSide,
  gaaTilSkattemeldingSide,
} from '../../actions/navigasjon';
import { useDispatcher } from '../../utils/reduxHooks';
import { useAnalytics } from '../../analytics/analytics.hooks';

export const Forside = () => {
  const intl = useIntl();
  const analytics = useAnalytics();

  const pageTitle = intl.formatMessage({ id: 'header.tittel' });
  useEffect(() => {
    document.title = pageTitle;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const skatteberegningRef = useRef(null);
  const [isSkatteberegningOpen, setIsSkatteberegningOpen] = useState(false);

  const gjeldendeSide = useSelector(getGjeldendeSide);
  const erGift = useSelector(getErSivilstandGift);
  const { beregnKplModus } = useKildeSkattPaaLoenn();
  const dispatchTilSkattemelding = useDispatcher(gaaTilSkattemeldingSide);
  const dispatchTilEktefelle = useDispatcher(gaaTilEktefelleSide);

  const toggleIsSkatteberegningOpen = () =>
    setIsSkatteberegningOpen(!isSkatteberegningOpen);

  const gaaTil = (side: string) => {
    if (side === EKTEFELLESIDE && side !== gjeldendeSide) {
      dispatchTilEktefelle();
      analytics.knappKlikk(
        'Gå til skattemelding for ektefelle',
        'Skattemelding',
      );
    } else if (side === SKATTEMELDINGSIDE && side !== gjeldendeSide) {
      dispatchTilSkattemelding();
      analytics.knappKlikk(
        'Gå til skattemeldingsiden',
        'Skattemelding ektefelle',
      );
    }
  };

  return erGift && !beregnKplModus ? (
    <>
      <InfoboksBeregnSkattenDin />
      <EstimertSkattTopp
        skatteberegningRef={skatteberegningRef}
        setIsSkatteberegningOpen={setIsSkatteberegningOpen}
      />
      <Tabs defaultValue={SKATTEMELDINGSIDE} value={gjeldendeSide}>
        <Tabs.List>
          <Tabs.Tab
            value={SKATTEMELDINGSIDE}
            onClick={() => gaaTil(SKATTEMELDINGSIDE)}
          >
            {intl.formatMessage({
              id: 'ledetekst.navigering.tilSkattemelding',
            })}
          </Tabs.Tab>
          <Tabs.Tab value={EKTEFELLESIDE} onClick={() => gaaTil(EKTEFELLESIDE)}>
            {intl.formatMessage({
              id: 'ledetekst.navigering.tilSkattemeldingEktefelle',
            })}
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value={SKATTEMELDINGSIDE}>
          <Skattemeldingside
            skatteberegningRef={skatteberegningRef}
            isSkatteberegningOpen={isSkatteberegningOpen}
            setIsSkatteberegningOpen={setIsSkatteberegningOpen}
            toggleIsSkatteberegningOpen={toggleIsSkatteberegningOpen}
          />
        </Tabs.Panel>
        <Tabs.Panel value={EKTEFELLESIDE}>
          <Skattemeldingside
            skatteberegningRef={skatteberegningRef}
            isSkatteberegningOpen={isSkatteberegningOpen}
            setIsSkatteberegningOpen={setIsSkatteberegningOpen}
            toggleIsSkatteberegningOpen={toggleIsSkatteberegningOpen}
          />
        </Tabs.Panel>
      </Tabs>

      <ScrollToTopButton />
    </>
  ) : (
    <>
      {!beregnKplModus && (
        <>
          <InfoboksBeregnSkattenDin />
          <EstimertSkattTopp
            skatteberegningRef={skatteberegningRef}
            setIsSkatteberegningOpen={setIsSkatteberegningOpen}
          />
        </>
      )}
      <Skattemeldingside
        skatteberegningRef={skatteberegningRef}
        isSkatteberegningOpen={isSkatteberegningOpen}
        setIsSkatteberegningOpen={setIsSkatteberegningOpen}
        toggleIsSkatteberegningOpen={toggleIsSkatteberegningOpen}
      />
      <ScrollToTopButton />
    </>
  );
};

const InfoboksBeregnSkattenDin = () => {
  const intl = useIntl();

  return (
    <Panel
      title={intl.formatMessage({ id: 'header.infoboks.beregnSkattenDin' })}
      titleAs="h1"
      color={'denim'}
      variant={'filled'}
      renderIcon={() => (
        <div className={style.beregnSkattenDinIkon}>
          <Icon svgPath={CalculatorSVGpath} size={'extraLarge'} />
        </div>
      )}
      spacing={'m'}
      className={style.beregnSkattenDinInfoboks}
    >
      <FormattedHTMLMessageWithFallbackToUgly
        id={'ledetekst.infoboks.beregnSkattenDin.innhold'}
      />
    </Panel>
  );
};

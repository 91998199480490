import { Icon } from '../DS';
import style from './Tema.module.scss';
import {
  AndreForholdSVGpath,
  ArbeidTrygdPensjonSVGpath,
  BankLaanForsikringSVGpath,
  BoligEiendelerSVGpath,
  FinansSVGpath,
  GaveArvSVGpath,
  KredittfradragSVGpath,
  NaeringsinntektSVGpath,
  PersonligeForholdSVGpath,
  SelskapDeltakerfastsettingSVGpath,
} from '@skatteetaten/ds-icons';

type TemaIkonProps = {
  id: string;
  className?: string;
};

export const TemaIkon = ({ id, className = style.ikon }: TemaIkonProps) => {
  return (
    <Icon
      svgPath={finnIconPathFraId(id)}
      className={className}
      variant="themeIcon"
    />
  );
};

const finnIconPathFraId = (id: string) => {
  switch (id) {
    case 'arbeidTrygdOgPensjon':
      return ArbeidTrygdPensjonSVGpath;
    case 'bankLaanOgForsikring':
      return BankLaanForsikringSVGpath;
    case 'eiendelerOgFasteEiendommer':
      return BoligEiendelerSVGpath;
    case 'finans':
      return FinansSVGpath;
    case 'skattefriArvGaveOgGevinst':
      return GaveArvSVGpath;
    case 'personligeForhold':
      return PersonligeForholdSVGpath;
    case 'familieOgHelse':
      return PersonligeForholdSVGpath;
    case 'andreForhold':
      return AndreForholdSVGpath;
    case 'naering':
      return NaeringsinntektSVGpath;
    case 'metodeForAaUnngaaDobbeltbeskatning':
      return KredittfradragSVGpath;
    case 'formueOgInntektISelskapMedDeltakerfastsetting':
      return SelskapDeltakerfastsettingSVGpath;

    default:
      return AndreForholdSVGpath;
  }
};

import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Spinner } from './DS';
import {
  getKalkulatorTekster,
  getKodelisterPrAar,
  getKodelisteTekster,
  getVdot,
  getVdotTekster,
} from '../../language/bundleMappings';
import { useInntektsaar } from '../../hooks/useInntektsaar';
import { teksterPerSpraak } from '../../language/smeTekster';
import { vdotOgTeksterHentet } from '../../reducers/visningsdefinisjon';
import { getLocale } from '../../reducers';
import matomoCoreUtil from '../../analytics/matomoCoreUtil';

const VdotContext = createContext<Vdot>({
  beregnetskattdefinisjon: undefined,
  skvisningsdefinisjon: undefined,
  visningsdefinisjon: undefined,
  kodelister: undefined,
});

export const useVdotContext = () => {
  return useContext(VdotContext);
};

export const fiksSkvisReferanse = (
  svalbard: boolean,
  skvisningsdefinisjon: any[],
) => {
  const svalbardPrefix = svalbard ? 'Svalbard' : '';

  const prefiks = `summertSkattegrunnlagForVisning${svalbardPrefix}`;
  return mapSkvisReferanse(prefiks, skvisningsdefinisjon);
};

const filterSvalbard =
  (svalbard: boolean) =>
  ({ forSvalbard }: any) =>
    forSvalbard === undefined ||
    (forSvalbard === true && svalbard) ||
    (forSvalbard === false && !svalbard);

const mapSkvisReferanse = (
  prefiks: string,
  skvis: any[] = [],
  svalbard: boolean = false,
) =>
  skvis.filter(filterSvalbard(svalbard)).map(({ ...barn }) => {
    if (barn.referanse) {
      barn.referanse = [prefiks, barn.referanse].join('.');
    }
    if (barn.barnliste) {
      barn.barnliste = mapSkvisReferanse(prefiks, barn.barnliste);
    }
    return barn;
  });

export const useAktivVdot = (svalbard: boolean = false) => {
  const vdotContext = useVdotContext();

  return {
    visningsdefinisjon: vdotContext.visningsdefinisjon,
    oppsummeringsdefinisjon:
      vdotContext.skvisningsdefinisjon &&
      fiksSkvisReferanse(svalbard, vdotContext.skvisningsdefinisjon),
    beregnetskattdefinisjon: vdotContext.beregnetskattdefinisjon,
  };
};

export const VdotContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { inntektsaar } = useInntektsaar();
  const [vdotState, setVdotState] = useState<
    | {
        vdot: Vdot;
        vdotTekster: Record<string, string>;
        kodelisteVerdier: Record<string, any>;
        kodelisteTekster: Record<string, string>;
        visningTekster: Record<string, string>;
      }
    | undefined
  >(undefined);

  const dispatch = useDispatch();

  const locale = useSelector(getLocale);

  useEffect(() => {
    Promise.all([
      getKodelisterPrAar(inntektsaar),
      getKodelisteTekster(locale),
      getVdot(inntektsaar),
      getVdotTekster(inntektsaar, locale),
      getKalkulatorTekster(inntektsaar, locale),
    ]).then(
      ([
        kodelisteVerdier,
        kodelisteTekster,
        vdot,
        vdotTekster,
        kalkulatorTekster,
      ]) => {
        setVdotState({
          kodelisteVerdier,
          kodelisteTekster,
          vdot,
          vdotTekster: {
            ...vdotTekster,
            ...kalkulatorTekster,
          },
          visningTekster: teksterPerSpraak(locale),
        });
      },
    );
  }, [inntektsaar, locale]);

  const vdots: Vdot | undefined = useMemo(
    () =>
      vdotState
        ? { ...vdotState.vdot, kodelister: vdotState.kodelisteVerdier }
        : undefined,
    [vdotState],
  );

  const messages: Record<string, string> | undefined = useMemo(
    () =>
      vdotState
        ? {
            ...vdotState.visningTekster,
            ...vdotState.kodelisteTekster,
            ...vdotState.vdotTekster,
          }
        : undefined,
    [vdotState],
  );

  useEffect(() => {
    if (vdotState?.vdot && messages) {
      matomoCoreUtil.setTekster(messages);
      dispatch(
        vdotOgTeksterHentet(messages, vdotState.vdot.visningsdefinisjon),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vdotState, messages]);

  if (!vdotState) {
    return <Spinner />;
  }

  return (
    <VdotContext.Provider value={vdots!}>
      <IntlProvider
        locale={locale}
        messages={messages}
        onError={(err) => {
          // TODO burde nok skru av denne en gang for å sjekke hva som faktisk mangler.
          //  Ser en del leggTil-knapper mangler men usikkert om disse skal med ennå
          if (
            err.code === 'MISSING_TRANSLATION' &&
            err.descriptor?.defaultMessage
          ) {
            return;
          }
          console.warn('Missing translation', err.message);
        }}
      >
        {children}
      </IntlProvider>
    </VdotContext.Provider>
  );
};

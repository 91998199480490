import { useIntl } from 'react-intl';
import classnames from 'classnames';

import { language } from '@skatteetaten/visningsdefinisjoner-og-tekster';
import style from './BeregnetSkatt.module.scss';
import oversiktStyle from '../Oversikt.module.scss';
import {
  harIkkeFradrag,
  harIkkeGrunnlag,
  skjulHvisAlleReferanserErNull,
} from './beregnetSkattUtils';
import { BeregnetSkattCelle } from './BeregnetSkattCelle';
import { defaultValueZero, hentVerdi } from './beregnetSkattUtils';

export const BeregnetSkattLinje = ({ linjeDef, beregnetSkatt, svalbard }) => {
  const intl = useIntl();
  const { harSkillelinje, skjulHvisReferanserErNull } = linjeDef;

  const verdi = hentVerdi(linjeDef, beregnetSkatt);

  if (
    skjulHvisAlleReferanserErNull(skjulHvisReferanserErNull, beregnetSkatt) ||
    harIkkeGrunnlag(linjeDef, verdi) ||
    harIkkeFradrag(linjeDef, verdi)
  ) {
    return null;
  }

  return (
    <dl
      className={classnames(oversiktStyle.flexTabellBeregnetSum, {
        [style.skillelinje]: harSkillelinje,
      })}
    >
      <dt className={oversiktStyle.flexCol1}>
        {intl.formatMessage({
          id: language.beregnetskattLedetekstId(
            `${linjeDef.id}${svalbard ? 'Svalbard' : ''}`,
          ),
          defaultMessage: intl.formatMessage({
            id: language.beregnetskattLedetekstId(linjeDef.id),
          }),
        })}
      </dt>
      <dd className={oversiktStyle.flexColBred}>
        <BeregnetSkattCelle
          value={verdi.beloep}
          defaultValue={defaultValueZero.beloep}
        />
      </dd>
    </dl>
  );
};

import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { Button } from '../../DS';
import style from './DialogKnapperad.module.css';
import { lukkDialog } from '../../../../actions/dialog';

type DialogAvbrytProps = {
  hoved?: boolean;
  aksjon?: (e: React.MouseEvent) => void;
  tekstid?: string;
  lukkEtterAksjon?: boolean;
};

export const DialogAvbryt = ({
  hoved,
  aksjon,
  tekstid = 'knapp.dialog.avbryt',
  lukkEtterAksjon = true,
}: DialogAvbrytProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const aksjon_ = useMemo(() => {
    return (e: React.MouseEvent) => {
      aksjon && aksjon(e);
      lukkEtterAksjon && dispatch(lukkDialog());
    };
  }, [dispatch, aksjon, lukkEtterAksjon]);

  return (
    <Button
      variant={hoved ? 'primary' : 'secondary'}
      onClick={aksjon_}
      className={style.luftMellomKnapper}
    >
      {intl.formatMessage({ id: tekstid })}
    </Button>
  );
};

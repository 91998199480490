import {
  ChangeEvent,
  forwardRef,
  JSX,
  RefAttributes,
  ForwardRefExoticComponent,
} from 'react';
import {
  RadioGroup as DSRadioGroup,
  RadioGroupProps as DSRadioGroupProps,
} from '@skatteetaten/ds-forms';
import { Radio, RadioProps } from './Radio';

type ChildType = JSX.Element | JSX.Element[] | false | null;
export interface RadioGroupProps
  extends Omit<DSRadioGroupProps, 'children' | 'onChange'> {
  children: ChildType | ChildType[];
  onChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
  hasError?: boolean;
}

export interface RadioGroupComponent
  extends ForwardRefExoticComponent<
    RadioGroupProps & RefAttributes<HTMLFieldSetElement>
  > {
  Radio: ForwardRefExoticComponent<
    RadioProps & RefAttributes<HTMLInputElement>
  >;
}

export const RadioGroup = forwardRef<HTMLFieldSetElement, RadioGroupProps>(
  (
    {
      children,
      legend,
      onChange,
      required,
      showRequiredMark,
      errorMessage,
      hasError,
      defaultValue,
      selectedValue,
      ...props
    }: RadioGroupProps,
    ref,
  ) => {
    const dsOnChange = (event: ChangeEvent<HTMLInputElement>) =>
      onChange(event, event.target.value);

    const valueProps = defaultValue
      ? {
          defaultValue,
        }
      : { selectedValue };

    const requiredProps = required
      ? {
          required,
          showRequiredMark,
        }
      : {};
    const errorProps = errorMessage
      ? {
          errorMessage,
          hasError,
        }
      : {};

    return (
      <DSRadioGroup
        ref={ref}
        legend={legend}
        onChange={dsOnChange}
        {...valueProps}
        {...requiredProps}
        {...errorProps}
        {...props}
      >
        {children as DSRadioGroupProps['children']}
      </DSRadioGroup>
    );
  },
) as RadioGroupComponent;

RadioGroup.displayName = 'SkattekalkulatorRadioGroup';
RadioGroup.Radio = Radio;

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import style from './Kortgruppe.module.scss';
import { KortgruppeHeader } from './KortgruppeHeader';
import { Korttype } from './kort/Korttype.container';
import { LeggTilKnapp } from './LeggTilKnapp';
import {
  useKanLeggeTilKort,
  useSkalKortgruppeSkjules,
} from './kortgruppeHooks';
import { useSkalKortgruppeFokuseres } from '../../../fokus/fokusHooks';
import { getVisningsdata } from '../../../reducers';
import { useFocusRef } from '../../../fokus/useFocusRef';
import { useKildeSkattPaaLoenn } from '../kildeskattePaaLoenn/useKildeskattPaaLoenn';

export const Kortgruppe = ({ kortgruppe, temaId }) => {
  const intl = useIntl();
  const { id, barnliste = [] } = kortgruppe;

  const visningsdata = useSelector(getVisningsdata);
  const { beregnKplModus, erBeregnKplOgKortSkalSkjules } =
    useKildeSkattPaaLoenn();

  const skalSkjules = useSkalKortgruppeSkjules(kortgruppe);

  const skalFokusere = useSkalKortgruppeFokuseres(kortgruppe.id);
  const skalFokusereTittel =
    skalFokusere &&
    barnliste
      .filter((barn) => barn.type === 'korttype')
      .some((barn) => !isEmpty(visningsdata[barn.referanse]));

  const skalFokusereLeggTilKnapp = skalFokusere && !skalFokusereTittel;
  const kanLeggeTilKort = useKanLeggeTilKort(kortgruppe);

  const focusLeggTilRef = useFocusRef(skalFokusereLeggTilKnapp);
  const focusTittelRef = useFocusRef(skalFokusereTittel);

  if (skalSkjules) {
    return null;
  }

  return (
    <div className={style.container}>
      <KortgruppeHeader
        title={intl.formatMessage({ id: id })}
        ref={focusTittelRef}
        kortgruppe={kortgruppe}
        temaId={temaId}
      />
      <div className={style.spacing}>
        <div className="xdl-grid">
          {barnliste
            .filter((kort) => !erBeregnKplOgKortSkalSkjules(kort.referanse))
            .map((barn) => {
              if (barn.type === 'korttype') {
                return (
                  <Korttype
                    key={barn.id}
                    id={barn.id}
                    korttype={barn}
                    temaId={temaId}
                  />
                );
              }
              return null;
            })}
        </div>

        {kanLeggeTilKort && !beregnKplModus && (
          <LeggTilKnapp
            kortgruppe={kortgruppe}
            temaId={temaId}
            ref={focusLeggTilRef}
          />
        )}
      </div>
    </div>
  );
};

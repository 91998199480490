import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import style from './DialogKnapperad.module.css';
import { lukkDialog } from '../../../../actions/dialog';
import { Button } from '../../DS';

type DialogOkProps = {
  hoved?: boolean;
  aksjon?: (e: React.MouseEvent) => void;
  submit?: boolean;
  lukkEtterAksjon?: boolean;
  disabled?: boolean;
};

export const DialogOk = ({
  hoved,
  aksjon,
  submit,
  lukkEtterAksjon,
  disabled,
}: DialogOkProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const aksjon_ = useMemo(() => {
    return (e: React.MouseEvent) => {
      aksjon && aksjon(e);
      lukkEtterAksjon && dispatch(lukkDialog());
    };
  }, [dispatch, aksjon, lukkEtterAksjon]);

  const typeProp = submit ? 'submit' : 'button';

  return (
    <Button
      variant={hoved ? 'primary' : 'secondary'}
      onClick={aksjon_}
      disabled={disabled}
      className={style.luftMellomKnapper}
      type={typeProp}
    >
      {intl.formatMessage({ id: 'knapp.dialog.ok' })}
    </Button>
  );
};

import { FormatertTall } from '../../util/FormatertTall';

type BeregnetSkattCelleProps = {
  value: number | undefined;
  defaultValue: string | number;
  skjulGrunnlag?: boolean;
};

export const BeregnetSkattCelle = ({
  value,
  defaultValue,
  skjulGrunnlag,
}: BeregnetSkattCelleProps) => {
  if (skjulGrunnlag) {
    return null;
  }
  if (value === undefined) {
    return <span>{defaultValue}</span>;
  }
  return <FormatertTall value={value} />;
};

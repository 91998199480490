import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { Panel } from '@skatteetaten/ds-content';
import { ArrowDownSVGpath } from '@skatteetaten/ds-icons';
import { Popover } from '@skatteetaten/ds-overlays';
import { Heading, Paragraph } from '@skatteetaten/ds-typography';
import { Button } from '../components/DS/Button';

import style from './EstimertSkattTopp.module.scss';

import { useBeregnetSkattData } from '../../hooks/useAktivSkvis';
import { scrollToPosition } from '../../utils/scroll';
import { getPos } from '../../utils/scrollUtils';
import { useInntektsaar } from '../../hooks/useInntektsaar';
import { formaterTall } from '../components/util/FormatertTall';
import classnames from 'classnames';

type EstimertSkattToppProps = {
  skatteberegningRef: MutableRefObject<HTMLDivElement | null>;
  setIsSkatteberegningOpen: (isOpen: boolean) => void;
  isScrolledPast?: boolean;
  erSticky?: boolean;
};

export const EstimertSkattTopp = ({
  skatteberegningRef,
  setIsSkatteberegningOpen,
  isScrolledPast,
  erSticky,
}: EstimertSkattToppProps) => {
  const seDetaljerRef = useRef<HTMLButtonElement>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const intl = useIntl();
  const { inntektsaar } = useInntektsaar();
  const beregnetSkattData = useBeregnetSkattData();
  const beregnetSkatt = beregnetSkattData?.beregnetSkatt | 0;

  const visDetaljer = () => {
    setIsSkatteberegningOpen(true);
    skatteberegningRef.current?.focus();
    scrollToPosition(getPos(skatteberegningRef.current));
  };

  useEffect(() => {
    if (seDetaljerRef && seDetaljerRef.current) {
      seDetaljerRef.current.setAttribute('aria-keyshortcuts', 'Shift+Alt+D');
    }

    const onKeyDown = (e: KeyboardEvent) => {
      if (e.code === 'KeyD' && e.altKey && e.shiftKey) {
        visDetaljer();
      }
    };
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  });

  if (!isScrolledPast && erSticky) {
    return null;
  }

  return (
    <Panel
      color={'forest'}
      spacing={erSticky ? 's' : 'm'}
      padding={erSticky ? 's' : 'm'}
      className={classnames(style.estimertSkattInfoboks, {
        [style.estimertSkattInfoboksSticky]: erSticky,
      })}
    >
      <div className={style.panelWrapper}>
        <div>
          <div className={style.headerWrapper}>
            <Heading
              id={'headerLabel'}
              as={'h2'}
              level={2}
              className={style.headerLabel}
            >
              <span
                aria-live="polite"
                aria-relevant="additions text"
                id="dynamic-info"
              >
                {intl.formatMessage(
                  {
                    id: `header.infoboks.beregnetSkatt.topp${
                      erSticky ? '.sticky' : ''
                    }`,
                  },
                  {
                    aar: inntektsaar,
                    beloep: `${formaterTall(beregnetSkatt, intl)}`,
                  },
                )}
              </span>
            </Heading>
            <Popover
              isOpen={isPopoverOpen}
              onClose={() => setIsPopoverOpen(false)}
            >
              <Popover.Trigger
                ariaDescribedby={'headerLabel'}
                onClick={() => setIsPopoverOpen(!isPopoverOpen)}
              />
              <Popover.Content>
                {intl.formatMessage({ id: 'hjelpetekst.beregnetSkatt' })}
              </Popover.Content>
            </Popover>
          </div>
          {!erSticky && (
            <Paragraph className={style.beloepTekst}>
              {`${formaterTall(beregnetSkatt, intl)} kr`}
            </Paragraph>
          )}
        </div>
        <div className={style.visDetaljerButton}>
          <Button
            ref={seDetaljerRef}
            variant={'secondary'}
            svgPath={ArrowDownSVGpath}
            onClick={() => visDetaljer()}
          >
            {intl.formatMessage({ id: 'knapp.vis.detaljer' })}
          </Button>
        </div>
      </div>
    </Panel>
  );
};

import {
  forwardRef,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';

import { ArrowBackSVGpath } from '@skatteetaten/ds-icons';
import style from './StickyHeader.module.scss';
import { Soek } from './soek/Soek';
import { TemaIkon } from './tema/TemaIkon';
import { useMediaQuery } from '../../hooks/mediaQueryHook';
import { useDisableScroll } from '../../hooks/useDisableScroll';
import { useScrolledPast } from '../../hooks/useScrolledPast';
import { getSynligSeksjon } from '../../reducers';
import { useAnalytics } from '../../analytics/analytics.hooks';
import { useKildeSkattPaaLoenn } from './kildeskattePaaLoenn/useKildeskattPaaLoenn';
import { EstimertSkattTopp } from './EstimertSkattTopp';
import { scrolletTilSeksjon } from '../../actions/navigasjon';
import { InlineButton } from './DS';

type HeaderWrapperProps = {
  isScrolledPast: boolean;
  children: React.ReactNode;
};

type StickyHeaderProps = {
  skatteberegningRef: MutableRefObject<HTMLDivElement | null>;
  setIsSkatteberegningOpen: (open: boolean) => void;
};

type TilbakeTilSideLinkProps = {
  href: string;
};

const stickyHeaderId = 'stickyHeader';

export const circaHeaderHoeyde = 130;

const HeaderWrapper = forwardRef<HTMLDivElement, HeaderWrapperProps>(
  ({ isScrolledPast, children }, ref) => {
    const dispatch = useDispatch();
    const synligSeksjon = useSelector(getSynligSeksjon);
    const [contentHeight, setContentHeight] = useState<number | undefined>(0);

    useEffect(() => {
      if (!isScrolledPast) {
        dispatch(scrolletTilSeksjon(null));
      }
    }, [isScrolledPast, dispatch]);

    useEffect(() => {
      if (ref && 'current' in ref && ref.current) {
        setContentHeight(ref.current.offsetHeight);
      }
    }, [ref, synligSeksjon]); // tema er med for å rekalkulere høyde når det tegnes inn/ut

    return (
      <div style={{ height: contentHeight }}>
        <div
          id={stickyHeaderId}
          className={isScrolledPast ? style.sticky : style.header}
          ref={ref}
        >
          {children}
        </div>
      </div>
    );
  },
);

export const StickyHeader = ({
  skatteberegningRef,
  setIsSkatteberegningOpen,
}: StickyHeaderProps) => {
  const intl = useIntl();
  const synligSeksjon = useSelector(getSynligSeksjon);
  const ref = useRef<HTMLDivElement>(null);
  const isScrolledPast = useScrolledPast(ref);

  const { mobile, desktop } = useMediaQuery();
  const [visSoekInput, settVisSoekInput] = useState(desktop);
  useEffect(() => settVisSoekInput(desktop), [desktop]);
  const visKunSoek = mobile && visSoekInput;
  useDisableScroll(visKunSoek);

  const { beregnKplModus } = useKildeSkattPaaLoenn();

  if (beregnKplModus) {
    return <TilbakeTilSideLink href="/skattekalkulator/skatteplikt" />;
  }

  if (visKunSoek) {
    return (
      <HeaderWrapper ref={ref} isScrolledPast={isScrolledPast}>
        <TilbakeTilSideLink href="/skattekalkulator/skatteplikt" />
        <Soek
          visInput={visSoekInput}
          settVisInput={settVisSoekInput}
          mediaDesktop={desktop}
        />
        <EstimertSkattTopp
          skatteberegningRef={skatteberegningRef}
          setIsSkatteberegningOpen={setIsSkatteberegningOpen}
          isScrolledPast={isScrolledPast}
          erSticky={true}
        />
      </HeaderWrapper>
    );
  }

  return (
    <HeaderWrapper ref={ref} isScrolledPast={isScrolledPast}>
      <div className={style.headerRow}>
        <div className={style.headerRowInnhold}>
          <TilbakeTilSideLink href="/skattekalkulator/skatteplikt" />
          <div className={style.soekContainer}>
            <Soek
              visInput={visSoekInput}
              settVisInput={settVisSoekInput}
              mediaDesktop={desktop}
            />
          </div>
        </div>
      </div>
      <EstimertSkattTopp
        skatteberegningRef={skatteberegningRef}
        setIsSkatteberegningOpen={setIsSkatteberegningOpen}
        isScrolledPast={isScrolledPast}
        erSticky={true}
      />
      <div
        className={classnames({
          [style.headerRow]: true,
          [style.temaRow]: true,
          [style.temaRowOpen]: synligSeksjon,
        })}
        aria-hidden={!synligSeksjon}
      >
        {synligSeksjon ? (
          <div className={style.temaRowInnhold}>
            <TemaIkon id={synligSeksjon} className={style.temaIkon} />
            {intl.formatMessage({ id: synligSeksjon })}
          </div>
        ) : null}
      </div>
    </HeaderWrapper>
  );
};

export const TilbakeTilSideLink = ({ href }: TilbakeTilSideLinkProps) => {
  const intl = useIntl();
  const navigateTo = useNavigate();
  const analytics = useAnalytics();

  return (
    <InlineButton
      onClick={() => {
        navigateTo(href);
        analytics.knappKlikk('Naviger til forrige side', href);
      }}
      svgPath={ArrowBackSVGpath}
    >
      {intl.formatMessage({
        id: 'ledetekst.navigering.tilbakeTilTilpasningsside',
      })}
    </InlineButton>
  );
};

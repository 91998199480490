import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import flatMap from 'lodash/flatMap';

import { Select } from '../DS/Select';
import { Button } from '../DS';
import style from './utils/DialogKnapperad.module.css';

import { oekendeSortering, sortArrayOfObjects } from '../../../utils/object';
import {
  getDialogTemaId,
  getVisningsdata,
  getVisningsdefinisjon,
} from '../../../reducers';
import { leggTilNyKortgruppe, lukkDialog } from '../../../actions/dialog';

import { StorDialog } from './utils/StorDialog';
import { ListeIDialog } from './utils/ListeIDialog';
import { DialogKnapperad } from './utils/DialogKnapperad';
import { DialogAvbryt } from './utils/DialogAvbryt';
import { KolonneInnhold } from './utils/KolonneInnhold';

import { filtrerVisningsdata } from './LeggTilOpplysningerDialog.util';
import { settFokusPaaSoekefelt } from '../../../fokus/SettFokusPaaSoekefelt';

const hentKortgrupperByTema = (nyeOpplysninger = [], temaId) =>
  flatMap(
    nyeOpplysninger.filter((tema) => (temaId ? tema?.id === temaId : tema)),
    (tema) => tema.barnliste,
  );

export const LeggTilOpplysningerDialog = () => {
  const temaId = useSelector(getDialogTemaId);
  const visningsdata = useSelector(getVisningsdata);
  const visningsdefinisjon = useSelector(getVisningsdefinisjon);
  const nyeOpplysninger = filtrerVisningsdata(visningsdata, visningsdefinisjon);

  const [valg, setValg] = useState(
    hentKortgrupperByTema(nyeOpplysninger, temaId),
  );
  const [aktivtTema, setAktivtTema] = useState(temaId || 'alle');
  const intl = useIntl();
  const dispatch = useDispatch();
  const leggTil = (kortgruppe) =>
    dispatch(leggTilNyKortgruppe(kortgruppe, temaId));

  const onChange = (e, changedOpt) => {
    const valgtValg =
      changedOpt === 'alle'
        ? hentKortgrupperByTema(nyeOpplysninger)
        : hentKortgrupperByTema(nyeOpplysninger, changedOpt);

    setAktivtTema(changedOpt.key);
    setValg(valgtValg);
  };

  const filter = nyeOpplysninger.map((tema) => ({
    key: tema?.id,
    text: intl.formatMessage({ id: tema?.id }),
  }));

  filter.unshift({
    key: 'alle',
    text: intl.formatMessage({
      id: 'ledetekst.vis.alle.tilgjengelige.opplysninger',
    }),
    isSelected: true,
  });

  const listeOverskrift = intl.formatMessage(
    { id: 'ledetekst.antall.nye.opplysninger' },
    { antall: valg.length },
  );

  const elementer = valg.map((kortgruppe) => {
    const valgTekst = intl.formatMessage({ id: kortgruppe.id });
    return {
      valgTekst: valgTekst,
      klikkAksjon: () => leggTil(kortgruppe),
    };
  });

  const elementerSortert = sortArrayOfObjects(
    elementer,
    (elem) => elem.valgTekst,
    oekendeSortering,
  );

  return (
    <StorDialog
      titleId="ledetekst.legg.til.nye.opplysninger"
      visObligatoriskFeltMelding={false}
    >
      <KolonneInnhold>
        <Select
          value={aktivtTema}
          id="legg-til-opplysninger-dropdown"
          label={intl.formatMessage({
            id: 'ledetekst.leggtilOpplysninger.dropdown',
          })}
          onChange={onChange}
        >
          {filter.map((option) => (
            <Select.Option key={option.key} value={option.key}>
              {option.text}
            </Select.Option>
          ))}
        </Select>
      </KolonneInnhold>
      <ListeIDialog tittel={listeOverskrift} elementer={elementerSortert} />
      <DialogKnapperad>
        <Button
          icon={'Search'}
          variant="secondary"
          className={style.soekKnapper}
          onClick={() => {
            dispatch(lukkDialog());
            settFokusPaaSoekefelt();
          }}
        >
          {intl.formatMessage({ id: 'knapp.soek' })}
        </Button>
        <DialogAvbryt />
      </DialogKnapperad>
    </StorDialog>
  );
};

import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import style from '../Oversikt.module.scss';
import { BeregnetSkattLinje } from './BeregnetSkattLinje';
import { BeregnetSkattGruppe } from './BeregnetSkattGruppe';
import {
  useBeregnetSkattData,
  useBeregnetSkattDefinisjon,
} from '../../../../hooks/useAktivSkvis';
import { filterSvalbard } from '../summertskattegrunnlag/summertSkattegrunlagUtil';

export const BeregnetSkatt = ({ svalbard = false }) => {
  const beregnetskattdefinisjon = useBeregnetSkattDefinisjon(svalbard);
  const beregnetSkatt = useBeregnetSkattData(svalbard);

  if (!beregnetSkatt) {
    return <FormattedMessage id="ledetekst.beregnetSkatt.ikkeHentet" />;
  }

  const visningslinje = (linje) => {
    const erGruppe =
      linje.barnliste !== null &&
      linje.barnliste !== undefined &&
      linje.barnliste.length > 0;

    if (erGruppe) {
      return (
        <BeregnetSkattGruppe
          key={linje.id}
          beregnetSkatt={beregnetSkatt}
          gruppe={linje}
          svalbard={svalbard}
          skjulHvisBeloepErNull={linje.skjulHvisBeloepErNull}
        />
      );
    }

    return (
      <BeregnetSkattLinje
        key={linje.id}
        linjeDef={linje}
        beregnetSkatt={beregnetSkatt}
        svalbard={svalbard}
      />
    );
  };

  return (
    <div>
      <div className={style.flexTabellBeregnetSkatt}>
        <h3 className={classnames(style.flexCol1, style.overskrift)}>
          <FormattedMessage
            id={`ledetekst.beregnetSkatt.skatteberegning${
              svalbard ? 'Svalbard' : ''
            }`}
          />
        </h3>
        <div
          className={classnames(
            style.flexCol2,
            style.overskriftLys,
            style.overskriftGrunnlagSkattOgAvgift,
          )}
        >
          <FormattedMessage id="ledetekst.beregnetSkatt.grunnlag" />
        </div>
        <div
          className={classnames(
            style.flexCol3,
            style.overskriftLys,
            style.overskriftGrunnlagSkattOgAvgift,
          )}
        >
          <FormattedMessage id="ledetekst.beregnetSkatt.skattOgAvgift" />
        </div>
      </div>

      {beregnetskattdefinisjon
        // TODO "sme". Burde ikke være her...?
        .filter((definisjon) => definisjon.applikasjon?.includes('sme') ?? true)
        .filter(filterSvalbard(svalbard))
        .map(visningslinje)}
    </div>
  );
};

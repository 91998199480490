import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import { Panel } from '@skatteetaten/ds-content';

import { RadioGroup } from '../components/DS/RadioGroup';
import { Alert } from '../components/DS/Alert';
import { Checkbox } from '../components/DS/Checkbox';
import { TextField } from '../components/DS/TextField';
import { Button } from '../components/DS/Button';
import { Select } from '../components/DS/Select';
import { skattepliktOppdatert } from '../../reducers/skatteplikt';
import {
  Errors,
  SivilstandType,
  SkattepliktsopplysningerType,
  validerSkattepliktsopplysninger,
} from './skattepliktsopplysninger.validator';
import { INNTEKTSAAR, NYESTE_INNTEKTSAAR } from '../../constants/inntektsaar';
import { FormattedHTMLMessageWithFallbackToUgly } from '../components/FormattedHTMLMessage';
import { useAnalytics } from '../../analytics/analytics.hooks';
import { Link } from '../components/DS/Link';
import { ArrowForwardSVGpath } from '@skatteetaten/ds-icons';
import { urlTilGammelKalkulator } from './VelgKalkulator';
import { TilbakeTilSideLink } from '../components/StickyHeader';
import { Heading } from '@skatteetaten/ds-typography';
import { ErrorSummary } from '@skatteetaten/ds-forms';
import style from './Skattepliktsopplysninger.module.scss';

const defaultState: SkattepliktsopplysningerType = {
  skatteberegningAar: NYESTE_INNTEKTSAAR,
  sivilstand: undefined,
  foedselsaar: undefined,
  foedselsaarEktefelle: undefined,
  borINorgeKunDelerAvAaret: false,
  finnmarksfradrag: false,
  antallMaanederINorge: undefined,
  antallMaanederINorgeEktefelle: undefined,
  vilBeregneKildeskattPaaLoenn: false,
};

export const Skattepliktsopplysninger = () => {
  const intl = useIntl();
  const [state, setState] = useState(defaultState);
  const [errors, setErrors] = useState<Errors>({});
  const [valideringAktivert, setValideringAktivert] = useState(false);
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const analytics = useAnalytics();

  useEffect(() => {
    if (valideringAktivert) {
      validate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valideringAktivert, state]);

  const pageTitle = intl.formatMessage({
    id: 'ledetekst.skattepliktsopplysninger.tittel',
  });

  useEffect(() => {
    document.title = pageTitle;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const analyticsLokasjon = 'Skattepliktsopplysninger side';
  useEffect(() => analytics.visning(analyticsLokasjon), [analytics]);
  const feltklikkTilAnalytics = (feltnavn: string) =>
    analytics.feltKlikk(feltnavn, analyticsLokasjon);
  const feltendringTilAnalytics = (feltnavn: string, nyVerdi: any) =>
    analytics.feltEndret(feltnavn, analyticsLokasjon, nyVerdi);

  const erGift = state.sivilstand === SivilstandType.GIFT;

  const validate = () => {
    const nyeErrors = validerSkattepliktsopplysninger(state, intl);
    setErrors(nyeErrors);
    return nyeErrors;
  };

  const dispatchSkattepliktOppdatering = (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();
    setValideringAktivert(true);
    const valideringsfeil = validate();
    if (isEmpty(valideringsfeil)) {
      analytics.knappKlikk('Gikk videre', analyticsLokasjon);
      dispatch(skattepliktOppdatert(state));
      navigateTo(`/skattekalkulator/${state.skatteberegningAar}`);
    } else {
      analytics.knappKlikk('Valideringsfeil', analyticsLokasjon);
    }
  };

  const update = (updates: any) => {
    setState((prevState) => ({
      ...prevState,
      ...updates,
    }));
  };

  const ledetekst = (key: string) =>
    intl.formatMessage({ id: `ledetekst.skattepliktsopplysninger.${key}` });
  const hjelpetekst = (key: string) =>
    intl.formatMessage({ id: `hjelpetekst.skattepliktsopplysninger.${key}` });

  const maanedOptions = [...Array(12).keys()].map((i) => {
    const maaned = i + 1;
    return (
      <Select.Option key={`maanedvalg-${maaned}`} value={maaned}>
        {intl.formatMessage(
          {
            id: 'ledetekst.skattepliktsopplysninger.antallMaanederINorge.valg',
          },
          {
            antallMaaneder: maaned,
          },
        )}
      </Select.Option>
    );
  });

  return (
    <div>
      <Panel
        color="forest"
        titleAs="h1"
        title={intl.formatMessage({
          id: 'ledetekst.skattepliktsopplysninger.skattekalkulatorEllerSkattekort.header',
        })}
        variant="filled"
      >
        <FormattedHTMLMessageWithFallbackToUgly id="ledetekst.skattepliktsopplysninger.skattekalkulatorEllerSkattekort" />
      </Panel>
      <div style={{ marginTop: '15px', marginBottom: '20px' }}>
        <TilbakeTilSideLink href="/" />
      </div>
      <form
        className={style.wrapper}
        onSubmit={(e) => dispatchSkattepliktOppdatering(e)}
      >
        <Heading as="h1" level={1}>
          {ledetekst('tittel')}
        </Heading>
        <div className={style.spacingM}>
          <p>{ledetekst('ingress')}</p>
        </div>
        <div className={style.spacingM}>
          <Alert showAlert>{ledetekst('felt.maa.fylles.ut')}</Alert>
        </div>

        <div className={style.spacingXL}>
          <RadioGroup
            id="input_skatteberegningAar"
            legend={ledetekst('skatteberegningAar')}
            selectedValue={state.skatteberegningAar}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const nyVerdi = Number(e.target.value);
              if (nyVerdi !== state.skatteberegningAar) {
                feltendringTilAnalytics('Skatteberegningsår', nyVerdi);
              }
              update({ skatteberegningAar: nyVerdi });
            }}
            helpText={hjelpetekst('skatteberegningAar')}
          >
            {Object.keys(INNTEKTSAAR)
              .reverse()
              .map((inntektsaar) => (
                <RadioGroup.Radio key={inntektsaar} value={Number(inntektsaar)}>
                  {`${inntektsaar}`}
                </RadioGroup.Radio>
              ))}
          </RadioGroup>
        </div>
        <Link
          href={urlTilGammelKalkulator}
          isExternal
          svgPath={ArrowForwardSVGpath}
        >
          {intl.formatMessage({
            id: 'ledetekst.velgKalkulator.gammelKalkulator',
          })}
        </Link>

        <div style={{ marginTop: '25px', maxWidth: '400px' }}>
          <Select
            id="input_sivilstand"
            label={ledetekst('sivilstand')}
            value={state.sivilstand}
            showRequiredMark
            placeholder={ledetekst('sivilstand.placeholder')}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const nyVerdi = e.target.value;
              if (nyVerdi !== state.sivilstand) {
                feltendringTilAnalytics('Sivilstand', nyVerdi);
              }
              update({ sivilstand: nyVerdi });
            }}
            errorMessage={errors.sivilstand}
            onFocus={() => feltklikkTilAnalytics('Sivilstand')}
          >
            {Object.values(SivilstandType).map((sivilstand) => {
              return (
                <Select.Option
                  value={sivilstand}
                  key={`sivilstand-option-${sivilstand}`}
                >
                  {ledetekst(`sivilstand.${sivilstand}`)}
                </Select.Option>
              );
            })}
          </Select>
        </div>

        <div className={style.inputWrapper}>
          <TextField
            id="input_foedselsaar"
            label={ledetekst('foedselsaar')}
            showRequiredMark
            errorMessage={errors.foedselsaar}
            onFocus={() => feltklikkTilAnalytics('Fødselsår')}
            onBlur={(e) => {
              const nyVerdi = Number(e.target.value);
              if (state.foedselsaar !== nyVerdi) {
                feltendringTilAnalytics('Fødselsår', nyVerdi);
              }
            }}
            onChange={(e) => update({ foedselsaar: Number(e.target.value) })}
          />
        </div>

        {erGift && (
          <div className={style.inputWrapper}>
            <TextField
              id="input_foedselsaarEktefelle"
              label={ledetekst('foedselsaarEktefelle')}
              showRequiredMark
              errorMessage={errors.foedselsaarEktefelle}
              onFocus={() => feltklikkTilAnalytics('Fødselsår ektefelle')}
              onChange={(e) => {
                const nyVerdi = Number(e.target.value);
                if (state.foedselsaarEktefelle !== nyVerdi) {
                  feltendringTilAnalytics('Fødselsår ektefelle', nyVerdi);
                }
                update({ foedselsaarEktefelle: nyVerdi });
              }}
            />
          </div>
        )}

        <div className={style.spacingXL}>
          <Checkbox
            id="input_borINorgeKunDelerAvAaret"
            checked={state.borINorgeKunDelerAvAaret}
            onChange={(
              _: React.ChangeEvent<HTMLInputElement>,
              value: boolean,
            ) => {
              feltendringTilAnalytics('Bor i Norge kun deler av året', value);
              update({
                borINorgeKunDelerAvAaret: value,
                antallMaanederINorge: defaultState.antallMaanederINorge,
              });
            }}
          >
            {ledetekst('borINorgeKunDelerAvAaret')}
          </Checkbox>
        </div>

        {state.borINorgeKunDelerAvAaret && (
          <div className={style.inputWrapper}>
            <Select
              id="input_antallMaanederINorge"
              label={ledetekst('antallMaanederINorge')}
              value={state.antallMaanederINorge}
              showRequiredMark
              helpText={hjelpetekst('antallMaanederINorge')}
              placeholder={ledetekst('antallMaanederINorge.placeholder')}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                const nyVerdi = Number(e.target.value);
                if (nyVerdi !== state.antallMaanederINorge) {
                  feltendringTilAnalytics('Antall måneder i Norge', nyVerdi);
                }
                update({ antallMaanederINorge: nyVerdi });
              }}
              errorMessage={errors.antallMaanederINorge}
              onFocus={() => feltklikkTilAnalytics('Antall måneder i Norge')}
            >
              {maanedOptions}
            </Select>
          </div>
        )}

        {state.borINorgeKunDelerAvAaret && erGift && (
          <div className={style.inputWrapper}>
            <Select
              id="input_antallMaanederINorgeEktefelle"
              label={ledetekst('antallMaanederINorgeEktefelle')}
              value={state.antallMaanederINorgeEktefelle}
              showRequiredMark
              helpText={hjelpetekst('antallMaanederINorgeEktefelle')}
              placeholder={ledetekst('antallMaanederINorge.placeholder')}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                const nyVerdi = Number(e.target.value);
                if (nyVerdi !== state.antallMaanederINorgeEktefelle) {
                  feltendringTilAnalytics(
                    'Antall måneder i Norge ektefelle',
                    nyVerdi,
                  );
                }
                update({ antallMaanederINorgeEktefelle: nyVerdi });
              }}
              errorMessage={errors.antallMaanederINorgeEktefelle}
              onFocus={() =>
                feltklikkTilAnalytics('Antall måneder i Norge ektefelle')
              }
            >
              {maanedOptions}
            </Select>
          </div>
        )}

        <div className={style.spacingM}>
          <Checkbox
            id="input_finnmarksfradrag"
            checked={state.finnmarksfradrag}
            onChange={(
              _: React.ChangeEvent<HTMLInputElement>,
              value: boolean,
            ) => {
              feltendringTilAnalytics('Finnmarksfradrag', value);
              update({ finnmarksfradrag: value });
            }}
          >
            {ledetekst('finnmarksfradrag')}
          </Checkbox>
        </div>

        <div className={style.spacingM}>
          <Checkbox
            id="input_vilBeregneKildeskattPaaLoenn"
            checked={state.vilBeregneKildeskattPaaLoenn}
            onChange={(
              _: React.ChangeEvent<HTMLInputElement>,
              value: boolean,
            ) => {
              feltendringTilAnalytics('Vil beregne KPL', value);
              update({ vilBeregneKildeskattPaaLoenn: value });
            }}
          >
            {ledetekst('kildeskattPaaLoenn')}
          </Checkbox>
        </div>

        <div style={{ marginTop: '20px' }}>
          <ErrorSummary showErrorSummary={Object.entries(errors).length > 1}>
            {Object.entries(errors).map(([key, message]) => (
              <ErrorSummary.Error key={key} referenceId={`input_${key}`}>
                {message}
              </ErrorSummary.Error>
            ))}
          </ErrorSummary>
        </div>

        <div className={style.spacingXL}>
          <Button type="submit">
            {intl.formatMessage({ id: 'knapp.neste' })}
          </Button>
        </div>
      </form>
    </div>
  );
};

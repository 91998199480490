import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import Cookies from 'universal-cookie';
import { useIntl } from 'react-intl';
import { Footer, TopBannerExternal } from '@skatteetaten/ds-layout';
import { getTopDomain, langToFulllocale } from '../../language/util';
import { oppdaterLocale } from '../../reducers/intl';
import style from './Layout.module.scss';

const cookies = new Cookies();

type Props = {
  title: string | JSX.Element | undefined;
  children: ReactNode;
};

export function Layout(props: Props) {
  const intl = useIntl();
  const dispatch = useDispatch();

  const onByttSpraak = (language: string) => {
    document.documentElement.setAttribute('lang', language);

    cookies.set('spraak', langToFulllocale(language), {
      path: '/',
      domain: getTopDomain(),
    });
    dispatch(oppdaterLocale(language));
  };

  return (
    <div className={style.layout}>
      <TopBannerExternal
        defaultLocale={cookies.get('spraak')}
        onLanguageClick={(e) => onByttSpraak(e.currentTarget.lang)}
        showSami={false}
      />
      <div className={style.container}>
        <main className={style.content}>{props.children}</main>
      </div>
      <Footer
        titleFirstColumn={intl.formatMessage({
          id: 'ledetekst.omSkatteetaten',
        })}
        accessibilityURL={intl.formatMessage({
          id: 'link.tilgjengelighetsErklaring',
        })}
      />
    </div>
  );
}

import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import { Button } from './DS';
import style from './KommandoKort.module.scss';

export const KommandoKort = forwardRef(
  ({ bordered = false, tekst, onClick, children }, ref) => {
    const mssKommandokort = classnames(
      style.kommandokort,
      { [style.borderStyle]: bordered },
      { [style.noBorderStyle]: !bordered },
      style.kommandoKortEkstraCss,
    );

    return (
      <div className={mssKommandokort}>
        <footer className="mss-renderkort__bunn">
          <Button
            variant="secondary"
            componentRef={ref}
            onClick={(args) => {
              onClick(args);
            }}
          >
            {tekst && <FormattedMessage id={tekst} defaultMessage={tekst} />}
            {children}
          </Button>
        </footer>
      </div>
    );
  },
);

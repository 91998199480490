import { forwardRef } from 'react';
import classnames from 'classnames';
import {
  Button as DSButton,
  ButtonProps as DSButtonProps,
} from '@skatteetaten/ds-buttons';

import style from './Button.module.css';
import { StringishChildren } from './DSTypes';

// https://skatteetaten.github.io/frontend-components/#button

export interface ButtonProps extends Omit<DSButtonProps, 'children'> {
  children: StringishChildren; // kan pr nå bare være string, som er tullete
  pressed?: boolean;
  loading?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { children, pressed, href, isExternal, disabled, type, form, ...props },
    ref,
  ) => {
    const buttonOrLinkProps = href
      ? {
          href,
          isExternal,
        }
      : {
          disabled,
          type,
          form,
        };

    return (
      <DSButton
        hasSpinner={props.loading}
        ref={ref}
        className={classnames(style.button, props.className, {
          [style.pressed]: pressed,
        })}
        {...buttonOrLinkProps}
        {...props}
      >
        {children as DSButtonProps['children']}
      </DSButton>
    );
  },
);

Button.displayName = 'SkattekalkulatorButton';

import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import style from './DialogKnapperad.module.css';
import { lukkDialog } from '../../../../actions/dialog';
import { Button } from '../../DS';

type DialogKnappProps = {
  hoved?: boolean;
  aksjon?: (e: React.MouseEvent) => void;
  tekstid?: string;
  tekst?: string;
  lukkEtterAksjon?: boolean;
  disabled?: boolean;
};

export const DialogKnapp = ({
  hoved,
  aksjon,
  tekstid,
  tekst,
  lukkEtterAksjon,
  disabled,
}: DialogKnappProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const tekst_ = tekstid ? intl.formatMessage({ id: tekstid }) : tekst || '';
  const aksjon_ = useMemo(() => {
    return (e: React.MouseEvent) => {
      aksjon && aksjon(e);
      if (lukkEtterAksjon) {
        dispatch(lukkDialog());
      }
    };
  }, [dispatch, aksjon, lukkEtterAksjon]);
  return (
    <Button
      variant={hoved ? 'primary' : 'secondary'}
      onClick={aksjon_}
      disabled={disabled}
      className={style.luftMellomKnapper}
    >
      {tekst_}
    </Button>
  );
};

import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';

import { language } from '@skatteetaten/visningsdefinisjoner-og-tekster';
import { useDispatcher } from '../../../utils/reduxHooks';
import style from './KortIDialog.module.scss';
import { getDialog } from '../../../reducers';
import {
  byttDialog,
  DIALOGTYPE_LEGG_TIL_OPPLYSNINGER,
} from '../../../actions/dialog';
import FormattedHTMLMessage from '../FormattedHTMLMessage';
import {
  egenskaperSomErLagtTil,
  finnAlleEgenskaper,
  finnDynamiskeFelterSomIkkeErLagtTil,
} from './KortOpplysninger/tilleggsopplysningerUtil';
import { useKildeSkattPaaLoenn } from '../kildeskattePaaLoenn/useKildeskattPaaLoenn';
import { InlineButton } from '../DS';
import { AddOutlineSVGpath } from '@skatteetaten/ds-icons';

export const LeggTilKortopplysninger = ({
  kortdefinisjon,
  forekomst,
  readOnly,
  disabled = false,
}) => {
  const {
    data: dialogData,
    dialogtype,
    synligeEgenskaper = {},
    kortIKortDialogProps: { underforekomstDataSti = {} } = {},
  } = useSelector(getDialog);

  const visFlereKortOpplysningerDialog = useDispatcher(
    byttDialog,
    DIALOGTYPE_LEGG_TIL_OPPLYSNINGER,
    dialogData,
    dialogtype,
  );
  const intl = useIntl();
  const { beregnKplModus } = useKildeSkattPaaLoenn();
  const { id } = kortdefinisjon;

  const alleEgenskaper = finnAlleEgenskaper(kortdefinisjon, forekomst).filter(
    (egenskap) =>
      !beregnKplModus || egenskap.referanse === 'trygdeEllerSkattemessigUnntak',
  );
  const egenskaperKanLeggesTil = !isEmpty(
    alleEgenskaper.filter(egenskaperSomErLagtTil(synligeEgenskaper)),
  );
  const kanLeggeTilDynamiskeFelt =
    !beregnKplModus &&
    !isEmpty(
      finnDynamiskeFelterSomIkkeErLagtTil(
        kortdefinisjon,
        dialogData,
        underforekomstDataSti,
      ),
    );
  const kanLeggeTilOpplysninger =
    egenskaperKanLeggesTil || kanLeggeTilDynamiskeFelt;

  if (readOnly || !kanLeggeTilOpplysninger) {
    return null;
  }
  const hasMessage = (tekstId) => !!intl.messages[tekstId];
  return (
    <fieldset className={style.leggTilOpplysningerTekst}>
      {hasMessage(language.flereopplysningerId(id, 'header')) && (
        <FormattedMessage
          id={language.flereopplysningerId(id, 'header')}
          tagName="legend"
        />
      )}
      {hasMessage(language.flereopplysningerId(id, 'beskrivelse')) && (
        <FormattedHTMLMessage
          id={language.flereopplysningerId(id, 'beskrivelse')}
          tagName="div"
        />
      )}
      <InlineButton
        disabled={disabled}
        svgPath={AddOutlineSVGpath}
        onClick={visFlereKortOpplysningerDialog}
        ariaLabel={intl.formatMessage({
          id: 'knapp.leggTilFlereOpplysninger',
          defaultMessage: 'Legg til flere opplysninger',
        })}
      >
        {intl.formatMessage({
          id: 'knapp.leggTilFlereOpplysninger',
          defaultMessage: 'Legg til flere opplysninger',
        })}
      </InlineButton>
    </fieldset>
  );
};

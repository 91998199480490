import { useIntl } from 'react-intl';
import { language } from '@skatteetaten/visningsdefinisjoner-og-tekster';
import { InlineButton } from '../../DS';
import { EditSVGpath } from '@skatteetaten/ds-icons';

const dontHandleClick = (e) => {
  e.preventDefault();
};

export const EndreKnapp = ({ id }) => {
  const intl = useIntl();

  return (
    <InlineButton
      onClick={dontHandleClick}
      svgPath={EditSVGpath}
      ariaLabel={''}
    >
      {intl.formatMessage({
        id: language.endreKnappId(id),
        defaultMessage: intl.formatMessage({ id: 'knapp.endre' }),
      })}
    </InlineButton>
  );
};

import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import Autosuggest from 'react-autosuggest';
import { isEmpty } from 'lodash';

import style from './Soek.module.scss';
import themeStyle from './SoekAutosuggestTheme.module.scss';
import { getSoekeResultat } from '../../../reducers';
import {
  soekFokusert,
  utfoerSoek,
  valgtSoekeResultat,
} from '../../../actions/soek';
import { useDispatcher } from '../../../utils/reduxHooks';
import {
  AddSVGpath,
  ArrowForwardSVGpath,
  CancelSVGpath,
  Icon,
  SearchSVGpath,
} from '@skatteetaten/ds-icons';
import { InlineButton } from '../DS';

const resultatRute = (suggestion) => {
  if (suggestion.erIngenResultat) {
    return (
      <div className={themeStyle.suggestionNoResult}>
        <FormattedMessage tagName="label" id="ledetekst.soek.ingen.treff" />
      </div>
    );
  }
  if (suggestion.kanVises) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a href="#" className={themeStyle.suggestionWithResult}>
        <Icon svgPath={ArrowForwardSVGpath} className={style.iconFontSize} />
        <span className={themeStyle.suggestiontitle}>
          {suggestion.visningslinje1}
        </span>
        <br />
        <span className={themeStyle.suggestionSubTitle}>
          {suggestion.visningslinje2}
        </span>
      </a>
    );
  }
  return (
    <div role="button" className={themeStyle.suggestionWithResult}>
      <Icon svgPath={AddSVGpath} className={style.iconFontSize} />
      <span className={themeStyle.suggestiontitle}>
        {suggestion.visningslinje1}
      </span>
      <br />
      <span className={themeStyle.suggestionSubTitle}>
        {suggestion.visningslinje2}
      </span>
    </div>
  );
};

const ingenResultat = {
  erIngenResultat: true,
};

const renderInputComponent = (intl, visSoekefeltVeiledning) => (inputProps) => (
  <>
    <div className={style.inputContainer}>
      <input
        aria-label={
          intl.formatMessage({ id: 'ledetekst.soek.plassholder' }) +
          ' ' +
          intl.formatMessage({ id: 'ledetekst.soekErAapent' })
        }
        {...inputProps}
      />
      <Icon svgPath={SearchSVGpath} className={style.icon} />
    </div>
    {inputProps.value === '' && visSoekefeltVeiledning && (
      <div className={style.soekErAapent}>
        <FormattedMessage tagName="label" id="ledetekst.soekErAapent" />
      </div>
    )}
  </>
);

const renderSuggestionsContainer = (suggestions, resultat, intl) => {
  return (
    <div {...suggestions.containerProps}>
      {resultat?.length > 0 && (
        <span aria-live="assertive" className={style.srOnly}>
          {intl.formatMessage(
            {
              id: 'ledetekst.soek.antallResultatVises',
            },
            { antall: resultat.length },
          )}
        </span>
      )}
      {suggestions.children}
    </div>
  );
};

export const Soek = ({ visInput, settVisInput, mediaDesktop }) => {
  const intl = useIntl();
  const soekRef = useRef();
  const containerRef = useRef();
  const [soekestreng, setSoekestreng] = useState('');
  const [visSoekefeltVeiledning, setVisSoekefeltVeiledning] = useState(false);
  const resultat = useSelector(getSoekeResultat);

  const soekFikkFokus = useDispatcher(soekFokusert);
  const utfoerSoek_ = useDispatcher(utfoerSoek);
  const aktiverResultat = useDispatcher(valgtSoekeResultat);

  useEffect(() => {
    !mediaDesktop &&
      visInput &&
      soekRef.current &&
      soekRef.current.input.focus();
  }, [mediaDesktop, visInput]);

  const inputProps = {
    value: soekestreng,
    onChange: (_, { newValue }) => {
      if (isEmpty(newValue)) setVisSoekefeltVeiledning(true);
      else setVisSoekefeltVeiledning(false);
      return setSoekestreng(newValue || '');
    },
    onFocus: () => {
      setVisSoekefeltVeiledning(true);
      soekFikkFokus();
    },
    onBlur: () => {
      settVisInput(mediaDesktop);
      setVisSoekefeltVeiledning(false);
    },
    type: 'search',
    placeholder: intl.formatMessage({ id: 'ledetekst.soek.plassholder' }),
    id: 'soek',
  };

  const resultatMedIngenResultat =
    resultat && resultat.length > 0 ? resultat : [ingenResultat];
  const aktiverResultatMedIngenResultatHaandtering = (valgt) =>
    !valgt.erIngenResultat && aktiverResultat(valgt);

  // Behold søkestreng ved keyboardnavigasjon i resultater
  const hentSoekestreng = () => soekestreng;
  const aTheme = {
    ...themeStyle,
    container: themeStyle.container,
    input: themeStyle.input,
  };

  return (
    <>
      {visInput ? (
        <>
          <div className={style.backdrop} aria-hidden />
          <div className={style.container} ref={containerRef} tabIndex={-1}>
            <div className={style.lukkSoek}>
              <InlineButton
                svgPath={CancelSVGpath}
                onClick={() => {
                  setVisSoekefeltVeiledning(false);
                  settVisInput(false);
                }}
              >
                {intl.formatMessage({ id: 'ledetekst.soek.lukk' })}
              </InlineButton>
            </div>
            <Autosuggest
              suggestions={resultatMedIngenResultat}
              onSuggestionsFetchRequested={({ value }) => utfoerSoek_(value)}
              onSuggestionSelected={(event, { suggestion }) => {
                event.preventDefault();
                setSoekestreng('');
                containerRef.current.focus();
                aktiverResultatMedIngenResultatHaandtering(suggestion);
                settVisInput(mediaDesktop);
              }}
              onSuggestionsClearRequested={() => utfoerSoek_('')}
              getSuggestionValue={hentSoekestreng}
              renderSuggestion={resultatRute}
              renderInputComponent={renderInputComponent(
                intl,
                visSoekefeltVeiledning,
              )}
              inputProps={inputProps}
              theme={aTheme}
              highlightFirstSuggestion
              ref={soekRef}
              renderSuggestionsContainer={(suggestions) =>
                renderSuggestionsContainer(suggestions, resultat, intl)
              }
            />
          </div>
        </>
      ) : (
        <InlineButton
          svgPath={SearchSVGpath}
          onClick={() => {
            setVisSoekefeltVeiledning(!visSoekefeltVeiledning);
            settVisInput(true);
          }}
          id={'aapneSoekKnapp'}
        >
          {intl.formatMessage({ id: 'knapp.soek' })}
        </InlineButton>
      )}
    </>
  );
};

import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash';

import { util } from '@skatteetaten/visningsdefinisjoner-og-tekster';
import { TextField } from '../DS';
import {
  lagFeilmeldinger,
  lagHjelpetekst,
  lagLabel,
} from '../../../utils/feltUtils';
import { erBeregnetFelt } from '../../../utils/visningsDataDefinisjonUtil';
import { useTransformerFelt } from '../../../utils/transformerHooks';
import { useAnalytics } from '../../../analytics/analytics.hooks';

const { erTall } = util;

export const MAX_INPUT_LENGTH = 200;

const unformat = (value) => {
  const doReplace = () =>
    value
      .replace(/\s/g, '') // => fjerner whitespace
      .replace(',', '.')
      .replace(/^0+(?!\.|$)/, ''); // => fjerner ledende nuller

  return value ? doReplace() : value;
};

export const valueFromEvent = (event) => event.target.value;

export const Inputfelt = ({
  placeholder,
  disabled = false,
  feil,
  felt,
  valideringsstate,
  obligatorisk,
  verdi = '',
  visningsVerdi = '',
  onChange,
  valider,
  focusElement,
  onFocus,
  suffix,
  maksLengde = MAX_INPUT_LENGTH,
}) => {
  const toEditValue = (value) => value?.replace('.', ',')?.trim();

  const [harFokus, setHarFokus] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [editValue, setEditValue] = useState(toEditValue(verdi));
  const focusRef = useRef(null);
  const intl = useIntl();
  const transformerFelt = useTransformerFelt();
  const analytics = useAnalytics();

  useEffect(() => {
    if (erBeregnetFelt(felt)) {
      setEditValue(toEditValue(verdi));
    }
  }, [verdi, felt]);

  useEffect(() => {
    if (felt.transformer?.args?.transformerFaktiskVerdiVedRendringAvFeltISme) {
      const transformertVerdi = transformerFelt(felt, verdi);
      if (verdi !== transformertVerdi) {
        onChange(transformertVerdi);
        setEditValue(transformertVerdi);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verdi, felt]);

  useLayoutEffect(() => {
    if (
      focusElement &&
      focusRef.current &&
      focusRef.current.textboxRef.current
    ) {
      focusRef.current.textboxRef.current.focus?.();
    }
  }, [focusElement]);

  useEffect(() => {
    if (!firstRender && typeof valider === 'function') {
      setFirstRender(true);
      valider(verdi);
    }
  }, [verdi, valider, firstRender]);

  const handleOnInput = (event) => {
    let val = valueFromEvent(event);
    if (erTall(felt)) {
      val = val.replace(/\s+/g, '');
    }
    if (typeof valider === 'function') {
      valider(val);
    }
    setEditValue(val);
  };

  const handleOnBlur = (event) => {
    setHarFokus(false);
    const trimmetVerdi = erTall(felt) ? unformat(editValue) : editValue?.trim();
    setEditValue(toEditValue(trimmetVerdi));
    if (typeof onChange === 'function' && trimmetVerdi !== verdi) {
      onChange(trimmetVerdi, event);
    }
  };

  const onFocus_ = () => {
    setHarFokus(true);
    if (typeof onFocus === 'function') {
      onFocus();
    }
  };

  // DS TextField har foreløpig ikke onClick event
  useEffect(() => {
    const analyticsClick = () => analytics.dialogFeltKlikk(felt.id);
    document.getElementById(felt.id)?.addEventListener('click', analyticsClick);
    return () =>
      document
        .getElementById(felt.id)
        ?.removeEventListener('click', analyticsClick);
  }, [analytics, felt.id]);

  const harFeil = feil && feil.length;

  return (
    <TextField
      id={felt.id}
      label={lagLabel(intl, felt, obligatorisk)}
      required={obligatorisk}
      value={!harFokus && !harFeil ? visningsVerdi : editValue}
      placeholder={placeholder}
      disabled={disabled}
      onChange={handleOnInput}
      onFocus={onFocus_}
      onBlur={handleOnBlur}
      ref={focusRef}
      onHelpToggle={analytics.haandterHjelpetekstToggle(felt.id)}
      errorMessage={lagFeilmeldinger(feil, valideringsstate, intl, felt.id)}
      helpText={lagHjelpetekst(intl, felt)}
      data-type={isEmpty(feil) ? '' : 'harFeil'}
      autoComplete="off"
      suffix={suffix}
      maxLength={maksLengde}
    />
  );
};

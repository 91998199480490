export const NYESTE_INNTEKTSAAR: number = 2025;

export const INNTEKTSAAR: {
  [key in number]: {
    vdotAar: number;
  };
} = {
  2024: {
    vdotAar: 2024,
  },
  2025: {
    vdotAar: 2024,
  },
};

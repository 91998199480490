import {
  InlineButton as DSInlineButton,
  InlineButtonProps as DSInlineButtonProps,
} from '@skatteetaten/ds-buttons';
import { StringishChildren } from './DSTypes';
import { forwardRef } from 'react';
import style from './InlineButton.module.css';
import classNames from 'classnames';

type InlineButtonProps = Omit<DSInlineButtonProps, 'children'> & {
  children: StringishChildren;
};

export const InlineButton = forwardRef<HTMLButtonElement, InlineButtonProps>(
  ({ children, ...props }, ref) => {
    return (
      <DSInlineButton
        {...props}
        ref={ref}
        className={classNames(style.padding, props.className)}
      >
        {children as DSInlineButtonProps['children']}
      </DSInlineButton>
    );
  },
);

import { useState } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { visningslogikk } from '@skatteetaten/visningsdefinisjoner-og-tekster';

import { VisningsfeltIDialog } from './VisningsfeltIDialog';
import { RedigerbartfeltIDialog } from './RedigerbartfeltIDialog';
import {
  erFeltseparator,
  Feltseparator,
  skalViseFeltseparator,
} from '../../Feltseparator';
import {
  FEILKILDE_BRUKERINPUT,
  FEILKILDE_SYSTEM,
  setFeilIDialog,
} from '../../../../reducers/feil.action';
import { lagFeltSti } from '../../../../utils/visningsdefinisjonUtil';
import { useTransformerFelt } from '../../../../utils/transformerHooks';
import { getDataIDialog, getFeilIDialog } from '../../../../reducers';
import { useValideringsstate } from '../../../../hooks/useValideringsstate';
import { useDispatcher } from '../../../../utils/reduxHooks';
import { endreFeltIDialog, onFeltEndret } from '../../../../actions/dialog';
import { useBetingelseHook } from '../../../../hooks/useBetingelseHook';

const { valider } = visningslogikk;

const erVisningFelt = (felt) => {
  const lesfelt = felt.modus === 'les';
  const ikkeFeltSeparator = !erFeltseparator(felt);

  return ikkeFeltSeparator && lesfelt;
};

export const FeltIDialog = ({
  felt,
  feltData,
  repeterendeFeltId,
  underforekomstId,
  forekomstId,
  feltReferanse,
  erEgenskap,
  disabled,
  readOnly,
  placeholder,
  focusElement,
  suffix,
  kortdefinisjon,
}) => {
  const feil = useSelector(getFeilIDialog);
  const valideringsstate = useValideringsstate();
  const data = useSelector(getDataIDialog);
  const setFeilIDialog_ = useDispatcher(setFeilIDialog);
  const endreFeltIDialog_ = useDispatcher(endreFeltIDialog);
  const onFeltEndret_ = useDispatcher(onFeltEndret);
  const transformerFelt = useTransformerFelt();

  const { foelgerBetingelse } = useBetingelseHook();

  const [verdiFoerEndring, setVerdiFoerEndring] = useState();

  const feltSti = lagFeltSti(repeterendeFeltId, felt, underforekomstId);

  const feilForFelt = () => {
    return get(feil, feltSti);
  };

  const oppdaterFeil = (nyeFeil, feilkilde) => {
    const erFeilEndret = () => {
      const gammel = feil[feltSti];
      const erLike =
        (isEmpty(gammel) && isEmpty(nyeFeil)) || isEqual(gammel, nyeFeil);
      return !erLike;
    };
    if (erFeilEndret()) {
      setFeilIDialog_(feltSti, nyeFeil, feilkilde);
    }
  };

  const onValider = (verdi) => {
    const validering = valider.feilmeldingerForFeltPerTastetrykk(
      felt,
      verdi,
      valideringsstate,
    );
    oppdaterFeil(validering, FEILKILDE_SYSTEM);
  };

  const onFocus = () => {
    setVerdiFoerEndring(feltData);
  };

  const onChange = (verdi) => {
    endreFeltIDialog_(forekomstId, feltReferanse, verdi, kortdefinisjon);

    if (verdiFoerEndring !== verdi) {
      onFeltEndret_(felt.id, feltSti, erEgenskap, verdiFoerEndring, verdi);
    }
    const validering = valider.feilmeldingerForFeltPaaFokusTap(
      felt,
      verdi,
      valideringsstate,
      data,
    );
    oppdaterFeil(validering, FEILKILDE_BRUKERINPUT);
  };

  if (erFeltseparator(felt) && skalViseFeltseparator('dialog', felt)) {
    return (
      <div>
        <Feltseparator felt={felt} />
      </div>
    );
  }

  if (erVisningFelt(felt) || readOnly) {
    return (
      <div>
        <VisningsfeltIDialog felt={felt} data={feltData} />
      </div>
    );
  }

  const verdi = get(feltData, feltReferanse, feltData);

  const transformertVerdi = transformerFelt(felt, verdi);

  const obligatorisk =
    !felt.disabled &&
    felt.valideringer?.some(
      (validering) =>
        validering === 'obligatorisk' ||
        (typeof validering === 'object' &&
          validering.navn === 'obligatorisk' &&
          validering.betingelse &&
          foelgerBetingelse(data, validering.betingelse)),
    );

  return (
    <RedigerbartfeltIDialog
      disabled={disabled}
      felt={felt}
      verdi={verdi}
      visningsVerdi={transformertVerdi}
      onChange={onChange}
      feil={feilForFelt()}
      valider={onValider}
      onFocus={onFocus}
      obligatorisk={obligatorisk}
      placeholder={placeholder}
      valideringsstate={valideringsstate}
      focusElement={focusElement}
      suffix={suffix}
      maksLengde={felt.maksLengde}
      feltReferanse={feltReferanse}
    />
  );
};

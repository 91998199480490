import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash';

import { Checkbox, ErrorMessage } from '../DS';
import { visningslogikk } from '@skatteetaten/visningsdefinisjoner-og-tekster';
import { lagFeilmeldinger, lagLabel } from '../../../utils/feltUtils';
import { useAnalytics } from '../../../analytics/analytics.hooks';

const { transformer } = visningslogikk;

const Avkrysningsboks = ({
  onChange,
  verdi,
  disabled,
  felt,
  feil,
  onFocus,
  valideringsstate,
}) => {
  const intl = useIntl();
  const analytics = useAnalytics();

  const handleOnChange = () => {
    analytics.dialogFeltKlikk(felt.id);

    if (onChange) {
      onChange(!(verdi?.toString() === 'true'));
    }
  };

  let message;
  if (felt.transformer.id === 'kodeliste') {
    const messageId = transformer.transformerFelt(felt, felt.referanse, {
      returnerId: true,
    });
    message = intl.formatMessage({ id: messageId });
  } else {
    message = lagLabel(intl, felt, false);
  }

  return (
    <>
      <Checkbox
        checked={verdi === 'true' || verdi === true}
        disabled={disabled}
        label={message}
        name={felt.id}
        onChange={handleOnChange}
        onFocus={onFocus}
        data-type={isEmpty(feil) ? '' : 'harFeil'}
      />
      <ErrorMessage showError={!isEmpty(feil)}>
        {lagFeilmeldinger(feil, valideringsstate, intl, felt.id)}
      </ErrorMessage>
    </>
  );
};

export default Avkrysningsboks;

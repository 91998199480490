import { Action, Store } from 'redux';
import { getItem, setItem } from '../utils/sessionStorage';

const sessionMiddleware = (store: Store) => (next: any) => (action: Action) => {
  const result = next(action);
  const state = store.getState();

  if (state.skatteplikt) {
    setItem('skatteplikt', state.skatteplikt);
  }
  if (state.visningsdata) {
    setItem('visningsdata', state.visningsdata);
  }
  if (state.visningsdataEktefelle) {
    setItem('visningsdataEktefelle', state.visningsdataEktefelle);
  }
  if (state.beregnetSkatt) {
    setItem('beregnetSkatt', state.beregnetSkatt);
  }
  if (state.beregnetSkattEktefelle) {
    setItem('beregnetSkattEktefelle', state.beregnetSkattEktefelle);
  }

  return result;
};

export const getSessionedState = (initialState: any) => {
  return {
    skatteplikt: getItem('skatteplikt') || initialState?.skatteplikt,
    visningsdata: getItem('visningsdata') || initialState?.visningsdata,
    visningsdataEktefelle:
      getItem('visningsdataEktefelle') || initialState?.visningsdataEktefelle,
    beregnetSkatt: getItem('beregnetSkatt') || initialState?.beregnetSkatt,
    beregnetSkattEktefelle:
      getItem('beregnetSkattEktefelle') || initialState?.beregnetSkattEktefelle,
  };
};

export default sessionMiddleware;

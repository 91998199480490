import { forwardRef } from 'react';
import {
  OpenClose as DSOpenClose,
  OpenCloseProps as DSOpenCloseProps,
} from '@skatteetaten/ds-collections';
import { StringishChildren } from './DSTypes';

type OpenCloseProps = Omit<DSOpenCloseProps, 'title'> & {
  title: StringishChildren;
};

export const OpenClose = forwardRef<HTMLButtonElement, OpenCloseProps>(
  ({ children, title, ...props }, ref) => {
    return (
      <DSOpenClose
        title={title as DSOpenCloseProps['title']}
        {...props}
        ref={ref}
      >
        {children}
      </DSOpenClose>
    );
  },
);

import React, { Children, cloneElement, isValidElement } from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';

import { IconButton } from '../../DS';
import style from './FeltDialogWrapper.module.scss';
import { KolonneInnhold } from './KolonneInnhold';
import { KolonneKontroller } from './KolonneKontroller';
import { Feltlayout } from '../../util/Feltlayout';
import { useKildeSkattPaaLoenn } from '../../kildeskattePaaLoenn/useKildeskattPaaLoenn';
import { DeleteSVGpath } from '@skatteetaten/ds-icons';

export const FeltIDialogWrapper = ({
  children,
  onSlett,
  erFeltIGruppe,
  readOnly,
  erFoersteFeltIGruppe,
  erSisteFeltIGruppe,
  gruppenHarFlereFelt,
  erEgenskap,
}) => {
  const intl = useIntl();
  const { beregnKplModus } = useKildeSkattPaaLoenn();

  const newChildren = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child);
    }
  });

  let classKolonneKontroller = style.ikonWrapper;
  if (erFeltIGruppe) {
    if (readOnly) {
      classKolonneKontroller = classnames({
        [style.tomIkonradPlaceholder]: true,
        [style.gruppeMarkering]: gruppenHarFlereFelt,
      });
    } else if (erFoersteFeltIGruppe) {
      classKolonneKontroller = classnames({
        [style.ikonWrapper]: true,
        [style.gruppeMarkeringForIkonrad]: gruppenHarFlereFelt,
      });
    } else {
      classKolonneKontroller = classnames({
        [style.tomIkonradPlaceholder]: true,
        [style.gruppeMarkering]: true,
        [style.gruppemarkeringSisteRad]: erSisteFeltIGruppe,
      });
    }
  }

  return (
    <Feltlayout>
      <KolonneInnhold erEgenskap={erEgenskap}>{newChildren}</KolonneInnhold>
      {!erEgenskap && !readOnly && !beregnKplModus && (
        <KolonneKontroller>
          <div className={classKolonneKontroller}>
            {erFoersteFeltIGruppe && (
              <IconButton
                title={intl.formatMessage({
                  id: 'knapp.slett',
                  defaultMessage: 'Slett',
                })}
                svgPath={DeleteSVGpath}
                onClick={onSlett}
                type="button"
              />
            )}
          </div>
        </KolonneKontroller>
      )}
    </Feltlayout>
  );
};

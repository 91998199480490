import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { get, size } from 'lodash';

import { language } from '@skatteetaten/visningsdefinisjoner-og-tekster';
import { OpenClose } from '../../DS';
import style from './FeltgruppeIDialog.module.scss';
import { FeltIDialog } from './FeltIDialog';
import { RepeterendefeltIDialog } from './RepeterendefeltIDialog';
import { FeltMedEgenskaperIDialog } from './FeltMedEgenskaperIDialog';
import { FeltIDialogWrapper } from './FeltIDialogWrapper';
import { DynamiskGruppeIDialog } from '../DynamiskGruppeIDialog';
import { FlerFeltVisning } from '../../FlerFeltVisning';
import { FormattedHTMLMessageWithFallbackToUgly } from '../../FormattedHTMLMessage';
import {
  getFeilIDialog,
  getSistOkKlikketTimestamp,
} from '../../../../reducers';
import {
  DYNAMISK_GRUPPE,
  erDynamiskgruppe,
  erFeltgruppe,
  erFeltMedEgenskaper,
  FELT,
  FELT_MED_EGENSKAPER,
  FELTGRUPPE,
  FLER_FELT_VISNING,
  REPETERENDE_FELTGRUPPE,
} from '../../../../utils/visningsDataDefinisjonUtil';
import { tekstId } from '../../../../utils/feltUtils';
import { useBetingelseHook } from '../../../../hooks/useBetingelseHook';

export const FeltgruppeIDialog = ({
  feltgruppe,
  data,
  kortdefinisjon,
  temaId,
  readOnly,
  dataSti,
}) => {
  const feil = useSelector(getFeilIDialog);
  const sistOkKlikketTimestamp = useSelector(getSistOkKlikketTimestamp);
  const {
    skalFeltDisables,
    skalFeltgruppeSkjules,
    skalFeltSkjules,
    skalEkspandereFeltgruppe,
  } = useBetingelseHook();

  const barnliste = useMemo(() => feltgruppe.barnliste || [], [feltgruppe]);

  const harFeltMedVerdi = (barn, dataForGruppe) =>
    barn.some((felt) => {
      if (erDynamiskgruppe(felt)) {
        return (get(dataForGruppe, felt.referanse) || []).some(
          (dynamiskFeltData) =>
            felt.barnliste
              .filter(
                (dynamiskFelt) =>
                  dynamiskFelt.datatype === dynamiskFeltData.behandlingsart,
              )
              .some((dynamiskFelt) =>
                harFeltMedVerdi(dynamiskFelt.barnliste, dynamiskFeltData),
              ),
        );
      }
      if (erFeltMedEgenskaper(felt)) {
        return get(
          dataForGruppe,
          `${felt.referanse}.${felt.belopsfelt.referanse}`,
        );
      }
      if (erFeltgruppe(felt)) {
        return harFeltMedVerdi(felt.barnliste, dataForGruppe);
      }
      return get(dataForGruppe, felt.referanse);
    });

  const [skalVise, setSkalVise] = useState(
    harFeltMedVerdi(barnliste, data) ||
      skalEkspandereFeltgruppe(feltgruppe, data),
  );
  const ekspanderFeltgruppe = feltgruppe.interaktiv === false || skalVise;

  useEffect(() => {
    const harFeil = barnliste.some(
      (felt) => feil[felt.id] && size(feil[felt.id]) > 0,
    );
    if (!skalVise && harFeil) {
      setSkalVise(false);
    }
  }, [sistOkKlikketTimestamp, skalVise, barnliste, feil]);

  if (skalFeltgruppeSkjules(feltgruppe, data)) {
    return null;
  }

  const FeltComp = () => (
    <div>
      {barnliste
        .filter((barn) => !barn.skjulIDialog)
        .filter((barn) => !skalFeltSkjules(barn, data))
        .map((barn) => {
          const disabled = skalFeltDisables(barn, data);
          switch (barn.type) {
            case FELT:
              return (
                <FeltIDialogWrapper
                  key={barn.id}
                  temaId={temaId}
                  felt={barn}
                  readOnly={readOnly}
                >
                  <FeltIDialog
                    disabled={disabled}
                    readOnly={readOnly}
                    felt={barn}
                    feltReferanse={barn.referanse}
                    feltData={get(data, barn.referanse)}
                    kortdefinisjon={kortdefinisjon}
                    forekomstId={data.id}
                  />
                </FeltIDialogWrapper>
              );

            case REPETERENDE_FELTGRUPPE:
              return (
                <RepeterendefeltIDialog
                  felt={barn}
                  data={data}
                  kortdefinisjon={kortdefinisjon}
                  key={barn.id}
                  readOnly={readOnly}
                  dataStiTilForelder={dataSti}
                />
              );

            case FELT_MED_EGENSKAPER:
              return (
                <FeltMedEgenskaperIDialog
                  key={barn.id}
                  disabled={disabled}
                  data={data}
                  readOnly={readOnly}
                  kortdefinisjon={kortdefinisjon}
                  felt={barn}
                  temaId={temaId}
                  feltReferanse={barn.referanse}
                />
              );

            case DYNAMISK_GRUPPE:
              return (
                <DynamiskGruppeIDialog
                  key={barn.id}
                  data={data}
                  kortdefinisjon={kortdefinisjon}
                  gruppe={barn}
                  referanse={barn.referanse}
                  temaId={temaId}
                  readOnly={readOnly}
                />
              );

            case FLER_FELT_VISNING:
              return (
                <FlerFeltVisning
                  key={barn.id}
                  feltdefinisjon={barn}
                  kortdefinisjon={kortdefinisjon}
                  forekomstData={data}
                />
              );

            case FELTGRUPPE:
              return (
                <FeltgruppeIDialog
                  key={barn.id}
                  feltgruppe={barn}
                  data={data}
                  kortdefinisjon={kortdefinisjon}
                  temaId={temaId}
                  readOnly={readOnly}
                  dataSti={dataSti}
                />
              );

            default:
              return null;
          }
        })}
    </div>
  );

  return (
    <fieldset className={style.feltgruppeWrapper}>
      {feltgruppe.interaktiv !== false && (
        <OpenClose
          title={<FormattedMessage id={feltgruppe.id} />}
          isDefaultExpanded={ekspanderFeltgruppe}
          showUnderline={false}
        >
          <FeltComp />
        </OpenClose>
      )}
      {feltgruppe.interaktiv === false && (
        <>
          <legend className={style.feltgruppeHeader}>
            <FormattedMessage id={feltgruppe.id} defaultMessage=" " />
          </legend>
          <div className={style.feltgruppeBeskrivelse}>
            <FormattedHTMLMessageWithFallbackToUgly
              id={language.beskrivelseId(tekstId(feltgruppe))}
              defaultMessage=" "
            />
          </div>
          <FeltComp />
        </>
      )}
    </fieldset>
  );
};

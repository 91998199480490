import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button } from '../DS';
import style from './LeggTilOpplysninger.module.scss';

import { nyLeggTilOpplysningerDialog } from '../../../actions/dialog';
import classNames from 'classnames';

export const LeggTilOpplysninger = () => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(nyLeggTilOpplysningerDialog());
  };

  return (
    <div className={style.container}>
      <h2 className={classNames(style.tittel, 'xdl-card__title-text')}>
        <FormattedMessage id="ledetekst.mangler.noe.i.skattemeldingen.din" />
      </h2>
      <Button onClick={onClick}>
        <FormattedMessage id="ledetekst.se.alle.tilgjengelige.opplysninger" />
      </Button>
    </div>
  );
};

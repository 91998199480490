import { forwardRef } from 'react';
import {
  Spinner as DSSPinner,
  SpinnerProps as DSSpinnerProps,
} from '@skatteetaten/ds-progress';

type SpinnerProps = DSSpinnerProps;

export const Spinner = forwardRef<HTMLDivElement, SpinnerProps>(
  (props, ref) => <DSSPinner {...props} ref={ref} />,
);

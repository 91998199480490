import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';

import { visningslogikk } from '@skatteetaten/visningsdefinisjoner-og-tekster';
import { ErrorMessage } from '../DS';
import style from './DynamiskGruppeIDialog.module.scss';
import { DynamiskFeltIDialog } from './DynamiskFeltIDialog';
import { fjernFokusBehandlingsart } from '../../../actions/dialog';
import {
  getAktivVisningsdata,
  getFeilIDialog,
  getForekomstForDialog,
  getNyBehandlingsart,
} from '../../../reducers';
import { lagFeilmeldinger } from '../../../utils/feltUtils';
import { overskrivFeilIDialog } from '../../../reducers/feil.action';
import { useBetingelseHook } from '../../../hooks/useBetingelseHook';
import { useValideringsstate } from '../../../hooks/useValideringsstate';

const { valider } = visningslogikk;

export const DynamiskGruppeIDialog = ({
  data,
  kortdefinisjon,
  gruppe,
  referanse,
  temaId,
  readOnly,
  repeterendeFeltId,
  underforekomstId,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const nyBehandlingsart = useSelector(getNyBehandlingsart);
  const valideringsstate = useValideringsstate();
  const forekomstIDialog = useSelector(getForekomstForDialog);
  const aktivVisningsdata = useSelector(getAktivVisningsdata);
  const { skalFeltDisables, skalFeltSkjules } = useBetingelseHook();

  useEffect(() => {
    dispatch(fjernFokusBehandlingsart());
  }, [dispatch]);

  const feil = useSelector(getFeilIDialog);
  const feilIDynamiskGruppe = get(feil, gruppe.id);

  useEffect(() => {
    if (gruppe.valideringer && feilIDynamiskGruppe?.length > 0) {
      const feilmeldinger = valider.feilmeldingerForKortType(
        kortdefinisjon,
        forekomstIDialog,
        valideringsstate,
        forekomstIDialog,
        aktivVisningsdata,
      );
      dispatch(overskrivFeilIDialog(feilmeldinger));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forekomstIDialog]);

  const { barnliste = [] } = gruppe;
  const behandlingsarter = get(data, referanse) || [];

  const dynamiskefelter = barnliste
    .filter((barn) =>
      behandlingsarter.find((art) => art.behandlingsart === barn.datatype),
    )
    .filter((barn) => !skalFeltSkjules(barn, data));

  // TODO hvorfor trengs ikke denne i sme...?
  if (isEmpty(dynamiskefelter)) {
    return null;
  }

  return (
    <div data-type={isEmpty(feilIDynamiskGruppe) ? '' : 'harFeil'}>
      <fieldset
        aria-describedby={data.id}
        className={classnames({
          [style.errorStyle]: !isEmpty(feilIDynamiskGruppe),
        })}
        tabIndex="-1"
      >
        {feilIDynamiskGruppe?.length > 0 && (
          <span id={data.id}>
            <ErrorMessage>
              {lagFeilmeldinger(
                feilIDynamiskGruppe,
                { kortReferanse: gruppe.referanse },
                intl,
              )}
            </ErrorMessage>
          </span>
        )}
        {dynamiskefelter.map((barn) => {
          const dynamiskfeltData = behandlingsarter.find(
            (art) => art.behandlingsart === barn.datatype,
          );
          const feltindex = behandlingsarter.findIndex(
            (art) => art.behandlingsart === barn.datatype,
          );
          const feltReferanse = `${referanse}.${feltindex}`;
          const fokusHvisNy = nyBehandlingsart
            ? dynamiskfeltData.id === nyBehandlingsart.id
            : false;

          return (
            <DynamiskFeltIDialog
              key={barn.id}
              kortdata={data}
              feltdata={dynamiskfeltData}
              kortdefinisjon={kortdefinisjon}
              dynamiskfelt={barn}
              temaId={temaId}
              feltReferanse={feltReferanse}
              readOnly={readOnly}
              focusElement={fokusHvisNy}
              repeterendeFeltId={repeterendeFeltId}
              underforekomstId={underforekomstId}
              disabled={skalFeltDisables(barn, {
                ...data,
                ...dynamiskfeltData,
              })}
            />
          );
        })}
      </fieldset>
    </div>
  );
};

import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import style from './Tema.module.scss';
import { ChevronDownSVGpath, ChevronUpSVGpath } from '@skatteetaten/ds-icons';
import { TemaIkon } from './TemaIkon';
import { Kortgruppe } from './Kortgruppe';
import { erKortgruppe } from '../../../utils/visningsDataDefinisjonUtil';
import { ResterendeKortgrupper } from './ResterendeKortgrupper';
import { useSynligSeksjon } from '../../../hooks/useSynligSeksjon';
import { useSkalTemaFokuseres } from '../../../fokus/fokusHooks';
import { useSkalTemaSkjules } from './temaHooks';
import { useFocusRef } from '../../../fokus/useFocusRef';
import { useKildeSkattPaaLoenn } from '../kildeskattePaaLoenn/useKildeskattPaaLoenn';
import { Icon } from '../DS/Icon';

export const Tema = ({ tema }) => {
  const { id, barnliste } = tema;

  const intl = useIntl();
  const { beregnKplModus, erBeregnKplOgKortgruppeSkalSkjules } =
    useKildeSkattPaaLoenn();
  const temaSkalFokuseres = useSkalTemaFokuseres(id);
  const temaErTomt = useSkalTemaSkjules(tema);
  const [isOpen, setIsOpen] = useState(!temaErTomt);

  useEffect(() => {
    setIsOpen(!temaErTomt);
  }, [temaErTomt]);

  const ref = useFocusRef(temaSkalFokuseres);
  const scrollRef = useRef(null);
  useSynligSeksjon(id, scrollRef);

  return (
    <div ref={scrollRef}>
      <div id={id} className={style.container}>
        {!beregnKplModus && (
          <>
            <button
              className={style.tittel}
              onClick={() => setIsOpen(!isOpen)}
              aria-expanded={isOpen}
            >
              <h2 className={style.titteltekst} ref={ref} tabIndex="-1">
                <TemaIkon id={id} />
                {intl.formatMessage({ id })}
              </h2>
              <div
                style={{
                  alignSelf: 'flex-end',
                  marginLeft: 'auto',
                  marginRight: '10px',
                }}
              >
                <Icon
                  svgPath={isOpen ? ChevronUpSVGpath : ChevronDownSVGpath}
                  size="large"
                />
              </div>
            </button>
          </>
        )}

        {isOpen &&
          barnliste
            .filter(erKortgruppe)
            .filter(
              (kortgruppe) =>
                !erBeregnKplOgKortgruppeSkalSkjules(kortgruppe.id),
            )
            .map((barn) => (
              <Kortgruppe key={barn.id} kortgruppe={barn} temaId={id} />
            ))}

        {!beregnKplModus && (
          <ResterendeKortgrupper temaId={id} inkluderTittel={isOpen} />
        )}
      </div>
    </div>
  );
};

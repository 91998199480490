import { forwardRef } from 'react';
import {
  ErrorMessage as DSErrorMessage,
  ErrorMessageProps as DSErrorMessageProps,
} from '@skatteetaten/ds-forms';
import { StringishChildren } from './DSTypes';

export interface ErrorMessageProps
  extends Omit<DSErrorMessageProps, 'children'> {
  children: StringishChildren;
}

export const ErrorMessage = forwardRef<HTMLDivElement, ErrorMessageProps>(
  ({ children, showError = false, ...props }, ref) => (
    <DSErrorMessage ref={ref} showError={showError} {...props}>
      {children as DSErrorMessageProps['children']}
    </DSErrorMessage>
  ),
);

ErrorMessage.displayName = 'SkattekalkulatorErrorMessage';

import { transformToKey } from './transformToKey';

import ledetekst_nb_NO from './locales/ledetekst_nb_NO.json';
import ledetekst_nn_NO from './locales/ledetekst_nn_NO.json';
import ledetekst_en_GB from './locales/ledetekst_en_GB.json';
import ledetekst_se_NO from './locales/ledetekst_se_NO.json';

import knapp_nb_NO from './locales/knapp_nb_NO.json';
import knapp_nn_NO from './locales/knapp_nn_NO.json';
import knapp_en_GB from './locales/knapp_en_GB.json';
import knapp_se_NO from './locales/knapp_se_NO.json';

import hjelpetekst_nb_NO from './locales/hjelpetekst_nb_NO.json';
import hjelpetekst_nn_NO from './locales/hjelpetekst_nn_NO.json';
import hjelpetekst_en_GB from './locales/hjelpetekst_en_GB.json';
import hjelpetekst_se_NO from './locales/hjelpetekst_se_NO.json';

import feil_nb_NO from './locales/feil_nb_NO.json';
import feil_nn_NO from './locales/feil_nn_NO.json';
import feil_en_GB from './locales/feil_en_GB.json';
import feil_se_NO from './locales/feil_se_NO.json';

import header_nb_NO from './locales/header_nb_NO.json';
import header_nn_NO from './locales/header_nn_NO.json';
import header_en_GB from './locales/header_en_GB.json';
import header_se_NO from './locales/header_se_NO.json';

import synonym_nb_NO from './locales/synonym_nb_NO.json';
import synonym_nn_NO from './locales/synonym_nn_NO.json';
import synonym_en_GB from './locales/synonym_en_GB.json';
import synonym_se_NO from './locales/synonym_se_NO.json';

import link_nb_NO from './locales/link_nb_NO.json';
import link_nn_NO from './locales/link_nn_NO.json';
import link_en_GB from './locales/link_en_GB.json';
import link_se_NO from './locales/link_se_NO.json';

const ledetekst = {
  nb: ledetekst_nb_NO,
  nn: ledetekst_nn_NO,
  en: ledetekst_en_GB,
  se: ledetekst_se_NO,
};

const knapp = {
  nb: knapp_nb_NO,
  nn: knapp_nn_NO,
  en: knapp_en_GB,
  se: knapp_se_NO,
};

const hjelpetekst = {
  nb: hjelpetekst_nb_NO,
  nn: hjelpetekst_nn_NO,
  en: hjelpetekst_en_GB,
  se: hjelpetekst_se_NO,
};

const feil = {
  nb: feil_nb_NO,
  nn: feil_nn_NO,
  en: feil_en_GB,
  se: feil_se_NO,
};

const header = {
  nb: header_nb_NO,
  nn: header_nn_NO,
  en: header_en_GB,
  se: header_se_NO,
};

const synonymer = {
  nb: synonym_nb_NO,
  nn: synonym_nn_NO,
  en: synonym_en_GB,
  se: synonym_se_NO,
};

const link = {
  nb: link_nb_NO,
  nn: link_nn_NO,
  en: link_en_GB,
  se: link_se_NO,
};

const ledetekstId = (id) => id.replace('ledetekst.', '');

// react-intl 4 er mindre tilgivende ovenfor ugyldig html.
// Den transformerer f.eks ikke <br>, som må skrives <br />.
// Vi har en bøtte med tekster som inneholder <br>, så vask verdien her.
// Får vi lignende behov, gjør det i samme metode.
const vaskTekster = (tekster) =>
  Object.entries(tekster).reduce((vaskedeTekster, [key, value]) => {
    vaskedeTekster[key] = value && value.replace(/<br>/gm, '<br />');
    return vaskedeTekster;
  }, {});

export const teksterPerSpraak = (spraak) => {
  const tekster = {
    ...transformToKey(ledetekst[spraak], ledetekstId, []),
    ...hjelpetekst[spraak],
    ...knapp[spraak],
    ...feil[spraak],
    ...header[spraak],
    ...synonymer[spraak],
    ...link[spraak],
  };

  return vaskTekster(tekster);
};

export const byggSmeTekster = () => ({
  nb: teksterPerSpraak('nb'),
  nn: teksterPerSpraak('nn'),
  en: teksterPerSpraak('en'),
  se: teksterPerSpraak('se'),
});

import moment from 'moment';
import { IntlShape } from 'react-intl';

// Typene itereres over på skattepliktsopplysningersiden
export enum SivilstandType {
  GIFT = 'gift',
  UGIFT = 'ugift',
  SEPARERT = 'separert',
  SKILT = 'skilt',
  ENKE = 'enke',
  ENKEMANN = 'enkemann',
}

export interface SkattepliktsopplysningerType {
  skatteberegningAar: number;
  sivilstand: SivilstandType | undefined;
  foedselsaar: number | undefined;
  foedselsaarEktefelle: number | undefined;
  borINorgeKunDelerAvAaret: boolean;
  finnmarksfradrag: boolean;
  antallMaanederINorge: number | undefined;
  antallMaanederINorgeEktefelle: number | undefined;
  vilBeregneKildeskattPaaLoenn: boolean;
}

export interface Errors {
  [key: string]: string;
}

export const validerSkattepliktsopplysninger = (
  state: SkattepliktsopplysningerType,
  intl: IntlShape,
) => {
  const erGyldigAar = (aar: number) => moment(aar, 'YYYY', true).isValid();

  const erGift = state.sivilstand === SivilstandType.GIFT;

  const obligatoriskFeltFeil = (felt?: string) =>
    intl.formatMessage({
      id: felt ? `feil.felt.obligatorisk.${felt}` : 'feil.felt.obligatorisk',
    });

  const validerFoedselsaar = (aar: number | undefined, ektefelle?: boolean) => {
    if (!aar) {
      return obligatoriskFeltFeil(
        ektefelle ? 'foedselsaarEktefelle' : 'foedselsaar',
      );
    }
    if (!erGyldigAar(aar)) {
      return intl.formatMessage({ id: 'feil.felt.aarstall' });
    }
    if (aar >= state.skatteberegningAar) {
      return intl.formatMessage({
        id: 'feil.skattepliktsopplysninger.foedselsaarMaaVaereMindreEnnInntektsaar',
      });
    }
    return undefined;
  };

  const foedselsaarError = validerFoedselsaar(state.foedselsaar);
  const foedselsaarEktefelleError = validerFoedselsaar(
    state.foedselsaarEktefelle,
    true,
  );

  const newErrors: Errors = {};

  if (!state.sivilstand) {
    newErrors.sivilstand = obligatoriskFeltFeil('sivilstand');
  }

  if (foedselsaarError) {
    newErrors.foedselsaar = foedselsaarError;
  }

  if (erGift && foedselsaarEktefelleError) {
    newErrors.foedselsaarEktefelle = foedselsaarEktefelleError;
  }

  if (state.borINorgeKunDelerAvAaret) {
    if (!state.antallMaanederINorge) {
      newErrors.antallMaanederINorge = obligatoriskFeltFeil(
        'antallMaanederINorge',
      );
    }
    if (erGift && !state.antallMaanederINorgeEktefelle) {
      newErrors.antallMaanederINorgeEktefelle = obligatoriskFeltFeil(
        'antallMaanederINorgeEktefelle',
      );
    }
  }
  return newErrors;
};

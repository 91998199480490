import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { visningslogikk } from '@skatteetaten/visningsdefinisjoner-og-tekster';

import style from './Dropdown.module.scss';
import { Select } from './DS';
import {
  getForekomstForDialog,
  getKodelisteConfig,
  getLocale,
  getTypeIDialog,
} from '../../reducers';
import {
  lagFeilmeldinger,
  lagHjelpetekst,
  lagLabel,
  mapOpsjoner,
} from '../../utils/feltUtils';
import { useAnalytics } from '../../analytics/analytics.hooks';
import { useKildeSkattPaaLoenn } from './kildeskattePaaLoenn/useKildeskattPaaLoenn';

const { felt: feltUtils } = visningslogikk;

export const Dropdown = React.forwardRef(
  (
    {
      valider,
      onChange,
      felt,
      verdi,
      feil,
      obligatorisk,
      valideringsstate,
      onFocus,
      disabled,
      feltReferanse,
    },
    ref,
  ) => {
    const intl = useIntl();
    const locale = useSelector(getLocale);
    const dialogData = useSelector(getForekomstForDialog);
    const kodelisteConfig = useSelector(getKodelisteConfig);
    const type = useSelector(getTypeIDialog);
    const analytics = useAnalytics();
    const {
      skalOverstyreKplTsuVerdier,
      gyldigeTrygdeEllerSkattemessigeUnntakForKpl,
    } = useKildeSkattPaaLoenn();

    const harFeil = !isEmpty(feil);
    const feltId = `combobox-${feltReferanse || ''}-${felt.id}`;

    useEffect(() => {
      if (!disabled && type === 'endre' && typeof valider === 'function') {
        valider(verdi);
      }
    }, [valider, verdi, disabled, type]);

    const gyldigeOpsjoner = useMemo(
      () =>
        skalOverstyreKplTsuVerdier(felt)
          ? gyldigeTrygdeEllerSkattemessigeUnntakForKpl
          : feltUtils.gyldigeOpsjonerForFelt(kodelisteConfig, felt, dialogData),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [dialogData],
    );

    const handleOnChange = useMemo(
      () => (event, value) => {
        if (typeof valider === 'function') {
          valider(value);
        }
        if (typeof onChange === 'function') {
          onChange(value, event);
        }
      },
      [valider, onChange],
    );

    const handleOnFocus = useMemo(
      () => (e) => {
        if (typeof onFocus === 'function') {
          onFocus(e);
        }
      },
      [onFocus],
    );

    useEffect(() => {
      const handleClick = () => analytics.dialogFeltKlikk(feltId);
      document.getElementById(feltId)?.addEventListener('click', handleClick);
      return () =>
        document
          .getElementById(feltId)
          ?.removeEventListener('click', handleClick);
    }, [analytics, feltId]);

    const feltDefMedGyldigeOpsjoner = {
      ...felt,
      opsjon: {
        ...felt.opsjon,
        verdier: gyldigeOpsjoner,
      },
    };

    const valgliste = mapOpsjoner(
      feltDefMedGyldigeOpsjoner,
      verdi,
      obligatorisk,
      intl,
      locale,
    );

    const errorMessage = lagFeilmeldinger(
      feil,
      valideringsstate,
      intl,
      felt.id,
    );

    return (
      <Select
        name={felt.id}
        ref={ref}
        id={feltId}
        value={verdi}
        className={style.dropdown}
        disabled={disabled}
        onChange={handleOnChange}
        onHelpToggle={analytics.haandterHjelpetekstToggle(feltId)}
        onFocus={handleOnFocus}
        options={valgliste}
        helpText={lagHjelpetekst(intl, felt)}
        label={lagLabel(intl, felt, obligatorisk)}
        data-type={harFeil ? 'harFeil' : ''}
        errorMessage={errorMessage}
      />
    );
  },
);

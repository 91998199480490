import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import style from './ResterendeKortgrupper.module.scss';
import { nyLeggTilOpplysningerDialog } from '../../../actions/dialog';
import { InlineButton } from '../DS';
import { AddOutlineSVGpath } from '@skatteetaten/ds-icons';
import classNames from 'classnames';

type ResterendeKortgrupperProps = {
  temaId: string;
  inkluderTittel?: boolean;
};

export const ResterendeKortgrupper = ({
  temaId,
  inkluderTittel,
}: ResterendeKortgrupperProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(nyLeggTilOpplysningerDialog(temaId));
  };

  const temaNavn = intl.formatMessage({ id: temaId });

  return (
    <fieldset className={style.container}>
      <legend
        className={classNames(
          style.tittel,
          inkluderTittel ? style.notSrOnly : style.srOnly,
        )}
      >
        {intl.formatMessage(
          { id: 'hjelpetekst.andreOpplysninger' },
          { tema: temaNavn },
        )}
      </legend>
      <div className={style.valg}>
        <InlineButton onClick={onClick} svgPath={AddOutlineSVGpath}>
          {intl.formatMessage({
            id: 'ledetekst.resterendekortgrupper.visLeggTil',
          })}
        </InlineButton>
      </div>
    </fieldset>
  );
};

import classnames from 'classnames';
import { InlineButton } from '../../DS';
import cssStyle from './ListeIDialog.module.scss';
import { ArrowForwardSVGpath } from '@skatteetaten/ds-icons';

type ListeIDialogProps = {
  tittel?: string;
  elementer: {
    valgTekst: string;
    klikkAksjon: () => void;
    disabled?: boolean;
  }[];
  ingenElementerTekst: string;
  style?: string;
  tittelStil?: string;
};

export const ListeIDialog = ({
  tittel,
  elementer,
  ingenElementerTekst,
  style,
  tittelStil,
}: ListeIDialogProps) => (
  <div>
    {tittel && (
      <div className={tittelStil || cssStyle.tittelStil}>{tittel}</div>
    )}
    <ul className={cssStyle.listeStil}>
      {elementer.length
        ? elementer.map((element, index) => (
            <li className={classnames(style)} key={index}>
              <InlineButton
                svgPath={ArrowForwardSVGpath}
                onClick={element.klikkAksjon}
                key={index}
                disabled={element.disabled}
                className={cssStyle.listeknappStil}
              >
                {element.valgTekst}
              </InlineButton>
            </li>
          ))
        : ingenElementerTekst}
    </ul>
  </div>
);

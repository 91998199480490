import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware, { END } from 'redux-saga';
import rootReducer from '../reducers';
import { rootSaga } from '../sagas';
import sessionMiddleware, { getSessionedState } from './sessionMiddleware';

// function haandterFeilFraPromise(dispatch) {
//   window.addEventListener('unhandledrejection', (event) => {
//     const feil = feilFraErrorOgLokasjon(event.reason, UNCAUGHT_IN_PROMISE);
//     dispatch(systemfeil(feil));
//   });
// }

export default function configureStore(
  initialState,
  ekstraMiddleware = [],
  rootSaga_ = rootSaga,
) {
  // const [
  //   sagaFeilhaandtering,
  //   setDispatchForSagaFeilhaandtering,
  // ] = haandterFeilFraSaga();
  //

  const sessionState = {
    ...initialState,
    ...getSessionedState(initialState),
  };

  const sagaMiddleware = createSagaMiddleware();
  // const sagaMiddleware = createSagaMiddleware({
  //   onError: sagaFeilhaandtering,
  // });

  const middlewares = [sagaMiddleware, sessionMiddleware, ...ekstraMiddleware];

  // eslint-disable-next-line
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // actionSanitizer: reduxDevtoolsActionSanitizer,
        maxAge: 20,
        // stateSanitizer: reduxDevtoolsStateSanitizer,
      })
    : compose;

  // TODO
  const store = createStore(
    rootReducer,
    sessionState,
    composeEnhancers(applyMiddleware(...middlewares)),
  );
  // setDispatchForSagaFeilhaandtering(store.dispatch);

  // haandterFeilFraPromise(store.dispatch);
  sagaMiddleware.run(rootSaga_);
  store.close = () => store.dispatch(END);
  return {
    store,
    // startSaga: () => {
    //   sagaMiddleware.run(rootSaga_);
    // },
  };
}

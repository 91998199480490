import style from './VelgKalkulator.module.scss';
import { useIntl } from 'react-intl';
import { useAnalytics } from '../../analytics/analytics.hooks';
import { useEffect } from 'react';
import { Panel } from '@skatteetaten/ds-content';
import { Heading } from '@skatteetaten/ds-typography';
import { NavigationTile } from '@skatteetaten/ds-navigation';
import { ArrowForwardSVGpath } from '@skatteetaten/ds-icons';

export const urlTilGammelKalkulator =
  'https://skattekalkulator-historisk.formueinntekt.skatt.skatteetaten.no/';

export const VelgKalkulator = () => {
  const intl = useIntl();
  const pageTitle = intl.formatMessage({
    id: 'ledetekst.velgKalkulator.tittel',
  });
  useEffect(() => {
    document.title = pageTitle;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const analytics = useAnalytics();

  const analyticsLokasjon = 'Velg kalkulator side';
  useEffect(() => analytics.visning(analyticsLokasjon), [analytics]);

  return (
    <div className={style.cardWrapper}>
      <Heading as="h1" level={1}>
        {intl.formatMessage({ id: 'ledetekst.velgKalkulator.tittel' })}
      </Heading>
      <Panel color="burgundy" className={style.card}>
        <ul className={style.cardContent}>
          <li>
            <NavigationTile
              size="extraLarge"
              className={style.navTile}
              ariaLabel={intl.formatMessage({
                id: 'ledetekst.velgKalkulator.nyKalkulator',
              })}
              title={intl.formatMessage({
                id: 'ledetekst.velgKalkulator.nyKalkulator',
              })}
              href={'/skattekalkulator/skatteplikt'}
              svgPath={ArrowForwardSVGpath}
            />
          </li>
          <li>
            <NavigationTile
              size="extraLarge"
              className={style.navTile}
              ariaLabel={intl.formatMessage({
                id: 'ledetekst.velgKalkulator.gammelKalkulator',
              })}
              title={intl.formatMessage({
                id: 'ledetekst.velgKalkulator.gammelKalkulator',
              })}
              href={urlTilGammelKalkulator}
              onClick={() =>
                analytics.knappKlikk(
                  'Naviger til gammel kalkulator',
                  analyticsLokasjon,
                )
              }
              svgPath={ArrowForwardSVGpath}
            />
          </li>
        </ul>
      </Panel>
    </div>
  );
};

import cl from 'classnames';
import {
  forwardRef,
  ForwardedRef,
  ChangeEvent,
  useCallback,
  ChangeEventHandler,
  ForwardRefExoticComponent,
  RefAttributes,
} from 'react';
import {
  Select as DSSelect,
  SelectProps as DSSelectProps,
  SelectOptionProps,
} from '@skatteetaten/ds-forms';
import style from './Select.module.css';

// Velger man placeholderen er det '' som settes
export const SELECT_PLACEHOLDER_VALUE = '';

export interface SelectOption {
  key: string | number;
  text: string;
  data?: any;
}

type OptionsChildrenProps =
  | {
      children?: never;
      options: SelectOption[];
    }
  | {
      children: DSSelectProps['children'];
      options?: never;
    };

export type SelectProps = Omit<DSSelectProps, 'onChange' | 'children'> & {
  onChange: (
    event: ChangeEvent<HTMLSelectElement>,
    value: string | number | undefined,
    option?: SelectOption,
  ) => void;
  hasError?: boolean;
} & OptionsChildrenProps;

export interface SelectComponent
  extends ForwardRefExoticComponent<
    SelectProps & RefAttributes<HTMLSelectElement>
  > {
  Option: ForwardRefExoticComponent<
    SelectOptionProps & RefAttributes<HTMLOptionElement>
  >;
}

const SelectComp = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      onChange,
      required,
      showRequiredMark = !!required,
      className,
      classNames,
      options,
      children,
      ...props
    }: SelectProps,
    ref: ForwardedRef<HTMLSelectElement>,
  ) => {
    const requiredProps = required
      ? {
          required,
          showRequiredMark,
        }
      : {};

    const dsOnChange: ChangeEventHandler<HTMLSelectElement> = useCallback(
      (event) => {
        const newValue =
          event.target.value === SELECT_PLACEHOLDER_VALUE
            ? undefined
            : event.target.value;

        if (options) {
          const option = options.find(
            (option) => String(option.key) === event.target.value,
          );

          onChange?.(event, option ? option.key : event.target.value, option);
        } else {
          onChange?.(event, newValue);
        }
      },
      [onChange, options],
    );

    const renderChildren = (
      options
        ? options.map((option) => (
            <DSSelect.Option key={option.key} value={option.key}>
              {option.text as SelectOptionProps['children']}
            </DSSelect.Option>
          ))
        : children
    ) as DSSelectProps['children'];

    return (
      <DSSelect
        ref={ref}
        onChange={dsOnChange}
        classNames={{
          ...classNames,
          container: cl(classNames?.container, className, style.wrapper),
        }}
        {...requiredProps}
        {...props}
      >
        {renderChildren}
      </DSSelect>
    );
  },
);

export const Select = Object.assign(SelectComp, {
  Option: DSSelect.Option,
}) as SelectComponent;

Select.displayName = 'SkattekalkulatorSelect';

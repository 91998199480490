import {
  Tabs as DSTabs,
  TabsProps as DSTabsProps,
} from '@skatteetaten/ds-collections';

type TabsProps = DSTabsProps;

export const Tabs = (props: TabsProps) => <DSTabs {...props} />;

Tabs.List = DSTabs.List;
Tabs.Tab = DSTabs.Tab;
Tabs.Panel = DSTabs.Panel;

import { useSelector } from 'react-redux';
import { INNTEKTSAAR, NYESTE_INNTEKTSAAR } from '../constants/inntektsaar';
import { getInntektsaar } from '../reducers';

export const useInntektsaar = () => {
  const inntektsaar = useSelector(getInntektsaar) || NYESTE_INNTEKTSAAR;

  return {
    inntektsaar: inntektsaar,
    vdotAar: INNTEKTSAAR[inntektsaar].vdotAar,
  };
};

import { useIntl, FormattedMessage } from 'react-intl';
import { get } from 'lodash';

import { Button, OpenClose, Link } from '../../../DS';
import Dialog from '@skatteetaten/frontend-components/Dialog';

import { GjeldsreduksjonOppsummering } from './GjeldsreduksjonOppsummering';
import { GjeldsreduksjonTabell } from './GjeldsreduksjonTabell';
import { verdsettingsrabatt } from '../../../../../constants/lenker';
import { useSkvisData } from '../../../../../hooks/useAktivSkvis';

export const GrunnlagForGjeldsreduksjon = ({ closeDialog, svalbard }) => {
  const intl = useIntl();
  const skvisData = useSkvisData(svalbard);

  const sumVerdsettingsrabatt = get(
    skvisData,
    'sumVerdsettingsrabattSomGirGjeldsreduksjon[0].beloep',
    0,
  );

  return (
    <>
      <Dialog
        id="gjeldsreduksjon"
        hidden={false}
        type={Dialog.Type.normal}
        onDismiss={closeDialog}
        title={intl.formatMessage({ id: 'ledetekst.gjeldsreduksjon.tittel' })}
        minWidth="400px"
        maxWidth="1000px"
        forceFocusInsideTrap
        modalProps={{ isBlocking: true, isModeless: false }}
      >
        <p>
          <FormattedMessage
            id={'ledetekst.gjeldsreduksjon.SideForklaring'}
            values={{
              verdsettingsLenke: (
                <Link href={verdsettingsrabatt} isExternal>
                  {intl.formatMessage({
                    id: 'ledetekst.gjeldsreduksjon.SideForklaring.lenketekst',
                  })}
                </Link>
              ),
            }}
          />
        </p>

        <p style={{ marginTop: '24px', marginBottom: '0' }}>
          {intl.formatMessage({
            id: 'ledetekst.gjeldsreduksjon.sumVerdsettingsrabatt',
          })}{' '}
          {intl.formatNumber(sumVerdsettingsrabatt)}
        </p>

        <OpenClose
          title={intl.formatMessage({
            id: 'ledetekst.gjeldsreduksjon.seDetaljer',
          })}
          showUnderline={false}
          variant="compact"
          iconPosition="right"
        >
          <GjeldsreduksjonTabell svalbard={svalbard} />
        </OpenClose>
        <hr />
        <GjeldsreduksjonOppsummering
          skvisData={skvisData}
          // harEktefelle={harEktefelle}
          svalbard={svalbard}
        />
        <Dialog.Footer>
          <Button variant="primary" onClick={closeDialog}>
            {intl.formatMessage({ id: 'knapp.dialog.ok' })}
          </Button>
        </Dialog.Footer>
      </Dialog>
    </>
  );
};

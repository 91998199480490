import { FormattedMessage, useIntl } from 'react-intl';
import { get } from 'lodash';
import classNames from 'classnames';

import { language } from '@skatteetaten/visningsdefinisjoner-og-tekster';

import { Button, IconButton } from '../../DS';

import style from './FeltlisteIDialog.module.scss';
import { FeltIDialog } from './FeltIDialog';
import { Feltlayout } from '../../util/Feltlayout';
import { KolonneInnhold } from './KolonneInnhold';
import { KolonneKontroller } from './KolonneKontroller';
import { tekstId } from '../../../../utils/feltUtils';
import { useBetingelseHook } from '../../../../hooks/useBetingelseHook';
import { AddOutlineSVGpath, DeleteSVGpath } from '@skatteetaten/ds-icons';

const RepeterendeFeltgruppe = ({
  data,
  kortdata,
  felt,
  index,
  kortdefinisjon,
  readOnly,
  disabled,
}) => {
  const { skalFeltDisables } = useBetingelseHook();
  return (
    felt.barnliste &&
    felt.barnliste.map((barn) => {
      return (
        <FeltIDialog
          disabled={
            disabled ||
            skalFeltDisables(barn, {
              ...data,
              ...kortdata,
            })
          }
          readOnly={readOnly}
          felt={barn}
          feltReferanse={[felt.referanse, index, barn.referanse].join('.')}
          feltData={get(data, barn.referanse)}
          kortdefinisjon={kortdefinisjon}
          key={barn.id}
          repeterendeFeltId={felt.id}
          underforekomstId={data.id}
          data={data}
          forekomstId={data.id}
        />
      );
    })
  );
};

export const FeltlisteIDialog = ({
  kortdata,
  felt,
  sorterteForekomster,
  kortdefinisjon,
  leggTilRepeterendefelt,
  slettRepeterendefelt,
  readOnly,
  disabled,
}) => {
  const intl = useIntl();

  const { barnliste, id } = felt;
  const repeterendefeltStil = classNames(
    style.repeterendefeltGruppeWrapper,
    barnliste.length > 1
      ? style.repeterendefeltGruppe
      : style.repeterendefeltGruppeEnkel,
  );

  return (
    <div className={style.repeterendefeltWrapper}>
      {sorterteForekomster &&
        sorterteForekomster.map((feltData, index) => (
          <div className={repeterendefeltStil} key={feltData.id}>
            <Feltlayout>
              <KolonneInnhold>
                {barnliste.length > 1 && !felt.skjulGruppeoverskrift && (
                  <label>
                    <FormattedMessage id={id} /> {index + 1}
                  </label>
                )}
                <RepeterendeFeltgruppe
                  felt={felt}
                  data={feltData}
                  kortdata={kortdata}
                  index={index}
                  kortdefinisjon={kortdefinisjon}
                  readOnly={readOnly}
                  disabled={disabled}
                />
              </KolonneInnhold>
              <KolonneKontroller>
                {index > 0 && !readOnly && (
                  <div className={style.slettKnapp}>
                    <IconButton
                      isOutlined
                      title={intl.formatMessage({ id: 'knapp.slett' })}
                      type="button"
                      svgPath={DeleteSVGpath}
                      onClick={() =>
                        slettRepeterendefelt(felt.referanse, feltData.id)
                      }
                    />
                  </div>
                )}
              </KolonneKontroller>
            </Feltlayout>
          </div>
        ))}
      {!readOnly && (
        <Button
          variant="secondary"
          svgPath={AddOutlineSVGpath}
          onClick={() => leggTilRepeterendefelt(felt.referanse)}
        >
          <FormattedMessage
            id={language.leggtilKnappId(tekstId(felt))}
            defaultMessage={intl.formatMessage({ id: 'knapp.leggTil' })}
          />
        </Button>
      )}
    </div>
  );
};

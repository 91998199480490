import { forwardRef, ChangeEvent, ReactNode } from 'react';
import {
  Checkbox as DSCheckbox,
  CheckboxProps as DSCheckboxProps,
} from '@skatteetaten/ds-forms';
import { StringishChildren } from './DSTypes';

export interface CheckboxProps
  extends Omit<DSCheckboxProps, 'children' | 'onChange' | 'description'> {
  children?: StringishChildren;
  label?: string;
  hasError?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>, value: boolean) => void;
  description?: ReactNode;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      children,
      label,
      onChange,
      required,
      showRequiredMark,
      errorMessage,
      hasError,
      description,
      ...props
    },
    ref,
  ) => {
    const dsOnChange = (event: ChangeEvent<HTMLInputElement>) =>
      onChange?.(event, event.target.checked);

    // må sendes inn i par pga discriminated unions i CheckboxProps
    const requiredProps = required
      ? {
          required,
          showRequiredMark,
        }
      : {};
    const errorProps = errorMessage
      ? {
          errorMessage,
          hasError,
        }
      : {};

    return (
      <DSCheckbox
        ref={ref}
        onChange={dsOnChange}
        description={description as DSCheckboxProps['description']}
        {...requiredProps}
        {...errorProps}
        {...props}
      >
        {(children || label || '') as DSCheckboxProps['children']}
      </DSCheckbox>
    );
  },
);

Checkbox.displayName = 'SkattekalkulatorCheckbox';
